import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle
} from 'react'
import PropTypes from 'prop-types'
import { formatDateString } from '../../utils'
import { headerData } from './header'
import { icons } from './icons'
import TurnboardManageNote from './turnboard-manage-note'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import {
  unitNotesCreate,
  unitNotesUpdate,
  unitNotesDelete,
  updateAllNotesGetByState,
  allNotesGet,
  resetAllNoteGET
} from '../../modules/notes'
import { updateTurnboardDataByState } from '../../modules/turnboard'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BasicLoading from '../../components/basic-loading'
import styled from 'styled-components'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy
} from 'react-table'

import './index.scss'
import PermissionGate from '../../components/permission-gate'

// Table styles
const Styles = styled.div`
  max-width: 100%;
  position: relative;
  input {
    border: 1px solid transparent;
    margin: 0px auto;
  }
  .note-table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid #e5e9f2;
    border-top: 0;
    position: relative;

    tr {
      position: relative;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      :first-child {
        th {
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    th {
      padding: 0px
      :first-child {
        border: 1px solid #e5e9f2;
      }
    }

    th,
    td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
    }
  }
`

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={'Search'}
    />
  )
}

const Table = memo(
  forwardRef(({ columns, data, deleteNoteId }, ref) => {
    // Use the state and functions returned from useTable to build your UI

    const defaultColumn = React.useMemo(
      () => ({
        width: 100,
        Filter: DefaultColumnFilter
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data,
        defaultColumn
      },
      useBlockLayout,
      useFilters,
      useSortBy,
      useRowSelect
    )

    useImperativeHandle(ref, () => ({
      getRows: () => {
        return rows?.map(row => row.original)
      }
    }))

    // Render the UI for your table
    return (
      <table {...getTableProps()} className="note-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </div>
                  <div title="Filter">
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            const { original } = row || {}
            const { id } = original || {}
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          textAlign: cell.column.textAlign,
                          width: cell.column.width,
                          background: id === deleteNoteId && '#fdeeec'
                        }
                      })}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  })
)

const TurnboardViewAllNotes = props => {
  const {
    onClose,
    setModalContent,
    closeModal,
    openModal,
    unitNotesCreate,
    unitNotesDelete,
    unitNotesUpdate,
    AllUnitIds,
    user,
    context,
    turnboard,
    updateTurnboardDataByState,
    allNotesGet,
    notes,
    resetAllNoteGET,
    selectedUnitIds = [],
    isFilterApplied,
    setRemoveAllActiveFilter,
    setupVendors
  } = props || {}
  const [deleteRequested, setDeleteRequested] = useState(false)
  const [deleteNoteId, setDeleteNoteId] = useState('')
  const [deleteUnitId, setDeleteUnitId] = useState('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [allNotes, setAllNotes] = useState([])
  const [isNotesLoading, setIsNotesLoading] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const tableRef = useRef(null)

  const onSuccess = () => {
    setIsNotesLoading(false)
  }
  const onError = () => {
    setIsNotesLoading(false)
  }

  useEffect(() => {
    setIsNotesLoading(true)
    getAllNotesData()
    return () => resetAllNoteGET()
  }, [])

  const getAllNotesData = async () => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    await allNotesGet(pdbid, context, onSuccess, onError)
  }

  // For all notes redux
  const { allNotes: allNotesInfo = {} } = notes || {}
  const { data: allNotesRawData = [], hasMadeInitialRequest, isRequesting } =
    allNotesInfo || {}

  useEffect(() => {
    // Sort Data
    let allNotesData = allNotesRawData || []

    // For getting selected unit(s) notes
    if ((selectedUnitIds || []).length !== 0 || isFilterApplied) {
      allNotesData = (allNotesData || []).filter(note =>
        (selectedUnitIds || []).includes(note.unit_id)
      )
    }
    setAllNotes(allNotesData)
  }, [allNotesRawData])

  // Icons
  const { close, exportFile, addButton, closeFilled } = icons || {}

  const manageNote = (row = {}, setRemoveAllActiveFilter) => {
    resetDelete()
    const {
      charge = '0',
      type = '',
      unit_id = '',
      note = '',
      id = '',
      servicetype_id = '',
      vendor_id = '',
      allNotesRawData = [],
      unit = '',
      isNew = false
    } = row || {}
    const manageNoteProps = {
      unitNotesCreate,
      user,
      context,
      noteCharge: charge,
      noteType: type,
      noteUnit: !isNew && {
        unit_id,
        unit
      },
      noteMessage: note,
      isNoteEdit: unit_id !== '',
      unitNotesUpdate,
      note_id: id,
      unitIds: AllUnitIds || [],
      updateTurnboardDataByState,
      turnboard,
      setRemoveAllActiveFilter,
      setupVendors,
      servicetype_id,
      vendor_id,
      notes: allNotesRawData
    }
    setModalContent(() => (
      <TurnboardManageNote
        {...manageNoteProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-manage-note-modal'
    })
  }

  const resetDelete = () => {
    setDeleteLoading(false)
    setDeleteNoteId('')
    setDeleteUnitId('')
    setDeleteRequested(false)
  }

  // Redux
  const { data: turnBoardData, activePage } = turnboard || {}
  const { units: allUnitsData } = turnBoardData || {}

  const onDeleteNoteSuccess = () => {
    setErrorMessage('')
    const filterAllStateNotes = (allNotes || []).filter(
      note => note.id !== deleteNoteId
    )
    setAllNotes(filterAllStateNotes)
    const filterAllNotes = (allNotesRawData || []).filter(
      note => note.id !== deleteNoteId
    )
    const getAllNotesUnitIds = (filterAllNotes || []).map(note => note.unit_id)
    const updateUnits = (allUnitsData || []).map(unitData => {
      const { unit_id } = unitData || {}
      if ((getAllNotesUnitIds || []).includes(unit_id)) {
        return {
          ...unitData,
          has_note: true
        }
      }
      return {
        ...unitData,
        has_note: false
      }
    })
    updateTurnboardDataByState({
      data: {
        page: activePage,
        units: updateUnits || []
      }
    })
    if (!isDeleted) {
      setIsDeleted(true)
      //setRemoveAllActiveFilter(true)
    }
    resetDelete()
  }
  const onDeleteNoteError = () => {
    resetDelete()
    setErrorMessage('Something went wrong, Please try again !!!')
  }

  const deleteNote = async () => {
    setDeleteLoading(true)
    const onError = onDeleteNoteError
    const onSuccess = onDeleteNoteSuccess
    const unitId = deleteUnitId
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}


   await unitNotesDelete({pdbid: pdbid, context:context, unitId: unitId, body: { note_id: deleteNoteId }, onSuccess: onSuccess, onError : onError,  type: 'turnboard'})
  }

  const exportCSV = () => {
    setErrorMessage('')
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    const data = (rows || []).map(noteData => {
      const { unit = '', type = '', creation_date = '', note = '' } =
        noteData || {}
      return {
        Unit: unit,
        Category: type,
        Date: creation_date
          ? moment(creation_date).format('DD-MMM-YYYY')
          : 'N/A',
        Message: `"${note || ''}"` || ''
      }
    })
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const csvContent = 'data:text/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `all-notes-${datetime}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const tableColumns = headerData({
    manageNote,
    setRemoveAllActiveFilter,
    setDeleteRequested,
    setDeleteNoteId,
    setDeleteUnitId,
    setErrorMessage,
    allNotesRawData
  })

  const columns = useMemo(() => tableColumns)

  return (
    <div id="turnboard-container" className="turnboard-all-notes-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <h2 className="is-pulled-left modal-sync-title">All Notes</h2>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div>
        <div className="is-flex notes-export-section">
          <PermissionGate name="manage-notes">
            <button
              className="is-flex is-align-items-center"
              onClick={() =>
                manageNote(
                  { allNotesRawData, isNew: true },
                  setRemoveAllActiveFilter
                )
              }>
              <img src={addButton} alt="Add Note" />
              Add Note
            </button>
          </PermissionGate>
          <button
            className="is-flex is-align-items-center export-icon"
            onClick={() => exportCSV()}>
            <img src={exportFile} alt="Export Notes" />
            Export
          </button>
        </div>
        {hasMadeInitialRequest && !isRequesting && !isNotesLoading ? (
          <>
            {errorMessage !== '' && (
              <div className="notification is-danger is-light is-flex error-section">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            )}
            {deleteRequested && deleteNoteId && deleteUnitId && (
              <div className="columns">
                <div className="column">
                  Do you really want to delete this note?
                </div>
                <div className="column is-narrow is-pulled-right">
                  <button
                    className={'button is-danger m-r-sm'}
                    onClick={() => deleteNote()}>
                    Yes
                    {deleteLoading && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        color="#ffffff"
                        className="m-l-sm"
                      />
                    )}
                  </button>
                  <button
                    className={'button is-default'}
                    onClick={() => resetDelete()}>
                    No
                  </button>
                </div>
              </div>
            )}

            <Styles>
              <Table
                ref={tableRef}
                columns={columns}
                data={allNotes}
                deleteNoteId={deleteNoteId}
              />
            </Styles>
          </>
        ) : (
          <BasicLoading />
        )}
      </div>
    </div>
  )
}

TurnboardViewAllNotes.propTypes = {
  onClose: PropTypes.func,
  unitNotesDelete: PropTypes.func,
  unitNotesCreate: PropTypes.func,
  unitNotesUpdate: PropTypes.func,
  setModalContent: PropTypes.func,
  allNotesGet: PropTypes.func,
  openModal: PropTypes.func,
  resetAllNoteGET: PropTypes.func,
  closeModal: PropTypes.func,
  updateTurnboardDataByState: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object,
  notes: PropTypes.object,
  AllUnitIds: PropTypes.array,
  turnboard: PropTypes.object,
  isFilterApplied: PropTypes.bool
}

const mapStateToProps = ({
  turnboard,
  user,
  context,
  notes,
  setupVendors
}) => ({
  turnboard,
  user,
  context,
  notes,
  setupVendors
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTurnboardDataByState,
      setModalContent,
      openModal,
      closeModal,
      unitNotesCreate,
      unitNotesUpdate,
      unitNotesDelete,
      updateAllNotesGetByState,
      allNotesGet,
      resetAllNoteGET
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnboardViewAllNotes)
