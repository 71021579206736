import React from 'react'
import { mainSidebarIcons } from 'ui/icons'
import { defaultServices } from './menuChildrenItems'
import get from 'lodash/get'
import find from 'lodash/find'
import { sectionAllowed } from '../../security'
import { makeQueryString } from '../../extendables/utility-component'
import { checkPermission } from '../../utils/helperFunctions'
import * as section from '../../utils/sectionConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  INCOMPLETED_UNITSPACES_REQUSTED,
  INCOMPLETED_UNITSPACES_SUCCESS
} from '../../modules/sidebar'
import { Tag } from './dropdownItem'
import { FlagFeaturePermissionGate } from '../../helpers/hooks'

const INSPECTION_PATH = 'inspections'
const INSPECTION_SETUP_PATH = 'inspections-setup'

export const menuItemTypes = {
  element: 'element',
  section: 'section',
  dropdown: 'dropdown'
}

export const getMenu = (
  user,
  context,
  property,
  currentServices,
  currentPath,
  downloadState,
  incompletedUnitspaces,
  incompletedUnitspacesStatus
) => {
  const { user_metadata } = user || {}
  const { properties, allowedSections, auto_goals } = user_metadata || {}

  const currentProperty = (properties || []).find(p => p.id === property)

  const getChildrenMenu = (children, childAllowedSections) => {
    const notAllowedChildren = []

    for (let section of childAllowedSections || []) {
      if (!checkPermission(allowedSections, section)) {
        notAllowedChildren.push(section)
      }
    }

    return (children || []).filter(
      child => !notAllowedChildren.includes(child?.id)
    )
  }

  let defaultMenuItems = [
    {
      iconSrc: mainSidebarIcons.homeIcon,
      altText: 'Home Icon',
      name: 'Home',
      path: '/',
      type: menuItemTypes.element
    }

    // {
    //   iconSrc: mainSidebarIcons.invoicesIcon,
    //   altText: 'Reports Icon',
    //   name: 'Reports',
    //   shouldHighlight: path => path.indexOf('/reports') !== -1,
    //   path: '/reports'
    // }
  ]

  if (checkPermission(allowedSections, section.DASHBOARD)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.dashboardIcon,
      name: 'Dashboard',
      altText: 'Dashboard Icon',
      shouldHighlight: path => path.indexOf('/dashboard') !== -1,
      children: [
        {
          name: 'Property',
          path: '/dashboard'
        }
        // {
        //   name: 'Regional',
        //   path: '/dashboard/rm'
        // },
        // {
        //   name: 'Corporate',
        //   path: '/dashboard/corporate'
        // }
      ],
      type: menuItemTypes.dropdown
    })
  }

  if (checkPermission(allowedSections, section.TURNBOARD)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.turnboardIcon,
      altText: 'Turnboard Icon',
      name: 'Turnboard',
      path: '/turnboard-2.0'
    })
  }

  if (checkPermission(allowedSections, section.KEYS)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.keysIcon,
      altText: 'Keys Icon',
      name: 'Keys',
      path: '/keys',
      render() {
        return (
          <FlagFeaturePermissionGate name={'KEYS_BUBBLE'}>
            <Tag
              incompletedUnitspaces={incompletedUnitspaces}
              incompletedUnitspacesStatus={incompletedUnitspacesStatus}
              childItem="Keys"></Tag>
          </FlagFeaturePermissionGate>
        )
      }
    })
  }

  if (checkPermission(allowedSections, section.WALK)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.walkIcon,
      name: 'Walk',
      altText: 'Walk Icon',
      shouldHighlight: path =>
        path.indexOf('/holdover') !== -1 ||
        path.indexOf('Walk') !== -1 ||
        path.indexOf('Holdover') !== -1,
      children: [
        {
          name: 'Walk',
          path: '/services?s=Walk'
        },
        // {
        //   name: 'Potential Holdovers',
        //   path: '/services?s=Holdover'
        // },
        {
          name: 'Holdovers',
          path: '/walk/holdovers'
        }
      ],
      type: menuItemTypes.dropdown
    })
  }

  if (checkPermission(allowedSections, section.INSPECT)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.inspectIcon,
      altText: 'Inspect Icon',
      name: 'Inspect',
      path: '/inspect'
    })
  }

  if (checkPermission(allowedSections, section.SERVICES)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.servicesIcon,
      name: 'Services',
      altText: 'Services Icon',
      shouldHighlight: path =>
        path.indexOf('services') !== -1 &&
        path.indexOf('Walk') === -1 &&
        path.indexOf('Holdover') === -1,
      children: currentServices.length ? currentServices : defaultServices,
      type: menuItemTypes.dropdown
    })
  }

  if (
    checkPermission(
      allowedSections,
      section.CHARGES,
      section.PURCHASE_ORDERS,
      section.APPROVALS
    )
  ) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.invoicesIcon,
      altText: 'Invoices Icon',
      name: 'Payables',
      shouldHighlight: path => path.indexOf('payables') !== -1,
      children: getChildrenMenu(
        [
          {
            name: 'Charges',
            path: '/payables/charges',
            id: section.CHARGES
          },
          {
            name: 'Purchase Orders',
            path: '/payables/purchase-orders',
            id: section.PURCHASE_ORDERS
          },
          {
            name: 'Approvals',
            path: '/payables/approvals',
            id: section.APPROVALS
          }
        ],
        [section.CHARGES, section.PURCHASE_ORDERS, section.APPROVALS]
      ),

      type: menuItemTypes.dropdown
    })
  }

  if (checkPermission(allowedSections, section.TURN_SETUP)) {
    const turnSetup = {
      iconSrc: mainSidebarIcons.setupIcon,
      name: 'Turn Setup',
      altText: 'Setup Icon',
      type: menuItemTypes.dropdown,
      shouldHighlight: path => path.indexOf('setup') !== -1,
      children: [
        /*{
          name: 'Properties',
          path: '/setup/properties'
        },*/
        /*{
          name: 'Services',
          path: '/setup/services'
        },*/
        {
          name: 'Vendors',
          path: '/setup/vendors'
        },
        {
          name: 'Staff',
          path: '/setup/staff'
        },
        {
          name: 'Teams',
          path: '/setup/teams'
        },
        {
          name: 'Budget',
          path: '/setup/budgets'
        }
      ]
    }

    if (auto_goals === 'false') {
      turnSetup.children.push({
        name: 'Goals',
        path: '/setup/goals'
      })
    }
    defaultMenuItems.push(turnSetup)
  }

  if (checkPermission(allowedSections, section.SCHEDULER)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.scheduleIcon,
      altText: 'Schedule Icon',
      name: 'Schedule',
      shouldHighlight: path => path.indexOf('/schedule') !== -1,
      children: [
        {
          name: 'Planning Tool',
          path: '/schedule/planning-tool'
        },
        {
          name: 'Boards',
          path: '/schedule/boards'
        }
      ],
      type: menuItemTypes.dropdown
    })
  }
  if (checkPermission(allowedSections, section.RFP)) {
    defaultMenuItems.push({
      iconSrc: mainSidebarIcons.walkIcon,
      altText: 'Vendors & Requests Icon',
      name: 'RFPs',
      shouldHighlight: path => path.indexOf('/requests-vendors') !== -1,
      children: [
        {
          name: 'Vendors & Requests',
          path: '/requests-vendors',
          shouldHighlight: path => path.indexOf('/requests-vendors') !== -1
        }
      ],
      type: menuItemTypes.dropdown
    })
  }

  if (!currentPath) {
    defaultMenuItems = [
      {
        iconSrc: mainSidebarIcons.homeIcon,
        altText: 'Home Icon',
        name: 'Home',
        path: '/',
        type: menuItemTypes.element
      }
    ]
  }

  if (currentPath === INSPECTION_PATH) {
    defaultMenuItems = [
      {
        iconSrc: mainSidebarIcons.homeIcon,
        altText: 'Home Icon',
        name: 'Home',
        path: '/',
        type: menuItemTypes.element
      }
    ]

    if (checkPermission(allowedSections, section.WEB_INSPECTIONS)) {
      defaultMenuItems = [
        ...defaultMenuItems,
        {
          iconSrc: mainSidebarIcons.turnboardIcon,
          name: 'Inspect Board',
          altText: 'Inspectboard Icon',
          path: '/inspections/inspectboard',
          type: menuItemTypes.element
        },
        {
          iconSrc: mainSidebarIcons.inspectIcon,
          name: 'Downloads',
          altText: 'Downloads Icon',
          path: '/inspections/downloads',
          type: menuItemTypes.element,
          render: downloadState ? () => <div className="badge-icon" /> : null
        }
      ]
    }
    if (checkPermission(allowedSections, section.DAMAGES)) {
      defaultMenuItems = [
        ...defaultMenuItems,
        {
          iconSrc: mainSidebarIcons.invoicesIcon,
          altText: 'Invoices Icon',
          name: 'Damages',
          shouldHighlight: path =>
            path.indexOf('/inspections/damagesetup') !== -1 ||
            path.indexOf('/inspections/damageinspection') !== -1 ||
            path.indexOf('/inspections/damages/createadhocdamage') !== -1,
          children: [
            {
              name: 'Setup',
              path: '/inspections/damagesetup',
              shouldHighlight: path =>
                path.indexOf('/inspections/damagesetup') !== -1
            },
            {
              name: 'Damages',
              path: '/inspections/damageinspection',
              shouldHighlight: path =>
                path.indexOf('/inspections/damageinspection') !== -1 ||
                path.indexOf('/inspections/damages/createadhocdamage') !== -1
            }
          ],
          type: menuItemTypes.dropdown
        }
      ]
    }
  }

  if (currentPath === INSPECTION_SETUP_PATH) {
    defaultMenuItems = [
      {
        iconSrc: mainSidebarIcons.homeIcon,
        altText: 'Home Icon',
        name: 'Home',
        path: '/',
        type: menuItemTypes.element
      }
    ]

    if (checkPermission(allowedSections, section.INSPECTION_SETUP)) {
      defaultMenuItems = [
        ...defaultMenuItems,
        {
          iconSrc: mainSidebarIcons.walkIcon,
          name: 'Elements',
          altText: 'Elements Icon',
          path: '/inspections-setup/elements',
          type: menuItemTypes.element
        },
        {
          iconSrc: mainSidebarIcons.inspectIcon,
          name: 'Company Templates',
          altText: 'Company Templates Icon',
          shouldHighlight: path =>
            path.includes('/inspections-setup/company-templates'),
          path: '/inspections-setup/company-templates',
          type: menuItemTypes.element
        },
        {
          iconSrc: mainSidebarIcons.inspectIcon,
          name: 'Property Forms',
          altText: 'Property Forms Icon',
          shouldHighlight: path =>
            path.indexOf('/inspections-setup/property-forms') !== -1,
          path: '/inspections-setup/property-forms',
          type: menuItemTypes.element
        }
      ]
    }
  }

  // if (
  //   !checkPermission(allowedSections, 'payableSideBarLink') &&
  //   !checkPermission(allowedSections, 'payableApprovalSideBarLink') &&
  //   currentProperty?.payables !== 'enable'
  // ) {
  //   defaultMenuItems = (defaultMenuItems || []).filter(
  //     e => e.name !== 'Payables'
  //   )
  // }

  return defaultMenuItems
}

export const isBlacklisted = (user, currentPath, property) => {
  if (!currentPath || !currentPath.path) return false
  const { path } = currentPath
  const { properties, allowedSections } = user.user_metadata

  const isPayableAllowed =
    checkPermission(allowedSections, 'payableSideBarLink') ||
    checkPermission(allowedSections, 'payableApprovalSideBarLink')

  const blacklist =
    properties && properties.length
      ? get(find(properties, p => p.id === property), 'blacklist', [])
      : []

  if (blacklist.indexOf(path) > -1) {
    return true
  }

  const routesDependingOnProperty = ['schedule', 'payables']
  const dependingRoute = routesDependingOnProperty.find(route =>
    path.includes(route)
  )

  if (dependingRoute && properties && properties.length) {
    const currentProperty = find(properties, p => p.id === property)

    if (currentProperty) {
      switch (dependingRoute) {
        case 'schedule':
          return sectionAllowed(path, null, user) && currentProperty.scheduler
            ? false
            : true

        case 'payables':
          return sectionAllowed(path, null, user) &&
            (currentProperty.payables === 'enable' || isPayableAllowed)
            ? false
            : true

        default:
          return true
      }
    }

    return true
  }

  return !sectionAllowed(path, null, user)
}

export const getIsActive = (currentPath, url, ignoreQueryString = false) => {
  const arr1 = currentPath.split('?')
  let filteredPath = currentPath
  if (arr1.length > 1) {
    let qs = arr1[1]
    const arr2 = qs.split('&')
    let params = {}
    for (let i = 0; i < arr2.length; i += 1) {
      const a = arr2[i].split('=')
      params[a[0]] = a[1]
    }
    delete params.p //Remove phase from the query string when comparing
    const newQs = makeQueryString(params)
    filteredPath = `${arr1[0]}${newQs}`
  }
  if (ignoreQueryString === true) filteredPath = arr1[0]
  return filteredPath === url ? 'is-active' : ''
}
