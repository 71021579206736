import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { REASONS } from './reasons'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'
import { getIncompleteUnitSpaces, getServicesByProperty } from '../../modules/sidebar'
import { useDispatch } from 'react-redux'

const ServicesModalReturn = ({
  closeModal,
  updateServices,
  user,
  services,
  rows,
  action,
  context,
  currentPhase,
  updateServicesStatus
}) => {
  const dispatch = useDispatch()
  const [reasonSelected, setReasonSelected] = useState('no-reason')
  const [hasStarted, setHasStarted] = useState(true)
  const [note, setNote] = useState('')
  const [error, setError] = useState('')
  const [statusSelected, setStatusSelected] = useState('no-status')
  const {properties} = user.user_metadata
  const currentProperty = properties.find(p => p.id === context.property)

  const currentService = (services || {})?.currentService?.replace('/', '%20')
  const { serviceAvailableStatus } = services || {}

  const sharedStatusData = [
    { key: 'Not Assigned', value: 'Not Assigned', filter: 'Assign' },
    { key: 'Assigned', value: 'Assigned', filter: 'Assign' },
    { key: 'In Progress', value: 'In Progress', filter: 'Executing' },
    { key: 'Ready for QC', value: 'Ready for QC', filter: 'AssignQc' },
    { key: 'Pending Approval', value: 'Pending Approval', filter: 'Approve' }
  ]

  const statusData = {
    Approved: sharedStatusData,
    Approve: sharedStatusData.slice(0, 4),
    AssignQC: sharedStatusData.slice(0, 3),
    InProgress: sharedStatusData.slice(0, 2)
  }

  const matchedServiceBackStatus = statusData[currentPhase]?.filter(item =>
    serviceAvailableStatus?.some(service => service.status === item.filter)
  )

  const modalTitle = useMemo(() => {
    let returnTo = ''

    switch (currentPhase) {
      case 'Approved':
        if (['Punch', 'Sparkle'].includes(currentService)) {
          returnTo = 'In Progress'
        } else {
          returnTo = 'Pending Approval'
        }
        break
      case 'Approve':
        returnTo = 'Ready for QC'
        break
      case 'AssignQC':
        returnTo = 'In Progress'
        break
      case 'InProgress':
        returnTo = 'Assigned'
        break
      case 'Start':
        returnTo = 'Not Assigned'
        break
      default:
        break
    }

    return `Return to ${returnTo}`
  }, [currentPhase, currentService])

  const units = useMemo(() => {
    return (rows || [])
      .map(row => row.unit)
      .join(', ')
      .trim()
  }, [rows])

  const resetModal = () => {
    setReasonSelected('no-reason')
  }

  const getReasonsOptions = () => {
    let template = []
    for (let i = 0; i < REASONS.length; i += 1) {
      let r = REASONS[i]
      template.push(
        <option key={r.key} value={r.key}>
          {r.display}
        </option>
      )
    }
    return template
  }

  const getReturnStatusOptions = () => {
    let template = []
    for (let i = 0; i < matchedServiceBackStatus?.length; i += 1) {
      let r = matchedServiceBackStatus[i]
      template.push(
        <option key={r.key} value={r.key}>
          {r.value}
        </option>
      )
    }
    return template
  }

  const { pdbid } = user?.user_metadata || {}

  const handleNoteChange = ({ currentTarget: input }) => {
    const _error = !input?.value?.trim() ? 'Please enter notes.' : ''

    setNote(input.value)
    setError(_error)
  }

   const onSuccess = () => {
    dispatch(getIncompleteUnitSpaces(pdbid,context, currentProperty.role.id))
  }

  const isSingleStatusReturn =
    currentPhase === 'Start' || currentPhase === 'Assign'

  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column">
          <p className="is-size-2 is-pulled-left">Return</p>
          <p
            className="is-size-4 is-pulled-right is-pointer is-unselectable"
            onClick={() => {
              closeModal()
              resetModal()
            }}>
            X
          </p>
        </div>
      </div>

      <div className="columns is-desktop is-mobile">
        <div className="column">
          <p className="is-size-6 is-inline m-r-md">Units</p>
          <p className="is-size-5">{units}</p>
        </div>
      </div>
      {!isSingleStatusReturn ? (
        <div className="columns is-desktop is-mobile">
          <div className="column">
            <p className="is-size-6 is-inline m-r-md">Return to</p>
            <div className="select is-fullwidth">
              <select
                className="input "
                value={statusSelected}
                onChange={e => setStatusSelected(e.target.value)}>
                <option value="no-status">Select</option>
                {getReturnStatusOptions()}
              </select>
            </div>
          </div>
        </div>
      ) : null}

      {/* {currentService !== 'Walk' && (
        <div className="columns">
          <div className="column is-three-quarters">
            <p className="is-size-6 is-inline m-r-md">Has this been started?</p>
            <label>
              <input
                className="is-inline"
                type="radio"
                value="yes"
                name="has_started"
                checked={hasStarted ? 'checked' : ''}
                onChange={() => setHasStarted(true)}
              />
              <span className="custom-radio"></span> Yes
            </label>
            <label>
              <input
                className="is-inline m-l-sm"
                type="radio"
                value="no"
                name="has_started"
                checked={hasStarted ? '' : 'checked'}
                onChange={() => setHasStarted(false)}
              />
              <span className="custom-radio"></span> No
            </label>
          </div>
        </div>
      )} */}
      <div className="columns">
        <div className="column ">
          <div className="m-b-sm m-t-sm">
            <label className="is-size-6">Notes</label>
            <textarea
              className="textarea"
              rows={3}
              name="notes"
              value={note}
              onChange={handleNoteChange}
            />
          </div>
          {error && (
            <ErrorMessage>
              <p>{error}</p>
            </ErrorMessage>
          )}
          {/* <div className="select is-fullwidth">
            <select
              className="input is-fullwidth"
              value={reasonSelected}
              onChange={e => setReasonSelected(e.target.value)}>
              <option value="no-reason">Select reason</option>
              {getReasonsOptions()}
            </select>
          </div> */}
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-right">
          <button
            className="button is-secondary m-r-sm"
            onClick={() => {
              closeModal()
              resetModal()
            }}>
            Cancel
          </button>
          <button
            className="button is-primary"
            onClick={ async () => {
              const body = {
                operation_uri: (rows || []).map(r => r.operation_uri).join(','),
                note: note.trim(),
                created_by: user?.user_id
              }
              if (isSingleStatusReturn) {
                body.action = currentPhase
              } else {
                body.desired_status = statusSelected
              }
              if (isSingleStatusReturn) {
              await  updateServices(pdbid, context, currentService, action, body, onSuccess, () => {})
              } else {
              await  updateServicesStatus(
                  pdbid,
                  context,
                  currentService,
                  action,
                  body,
                  onSuccess,
                  () => {}
                )
              }

              closeModal(true)
            }}
            disabled={
              error ||
              !note.length ||
              (!isSingleStatusReturn && statusSelected === 'no-status')
            }>
            Return
          </button>
        </div>
      </div>
    </div>
  )
}

ServicesModalReturn.propTypes = {
  closeModal: PropTypes.func,
  updateServices: PropTypes.func,
  user: PropTypes.object,
  services: PropTypes.object,
  action: PropTypes.string,
  row: PropTypes.object,
  modal: PropTypes.object,
  context: PropTypes.object
}

export default ServicesModalReturn
