import _, { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { usePrevious } from '../../helpers/hooks'
import {
  getServicesList,
  getServicesStats,
  getServicesTeams,
  setCurrentPhase,
  setCurrentService,
  markCompleteService,
  unblockService,
  updateServices,
  getUnitHistory,
  resetUnitHistory,
  getServiceColumns,
  getServicesAvailableStatus,
  updateServicesStatus
} from '../../modules/services'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import {
  setTooltipContent,
  openTooltip,
  closeTooltip
} from '../../modules/tooltip'
import ServicesMain from '../../parents/services-main'

const Services = ({
  context,
  auth,
  user,
  services,
  responsive,
  setModalContent,
  openModal,
  closeModal,
  setCurrentPhase,
  setCurrentService,
  location,
  history,
  markCompleteService,
  unblockService,
  updateServices,
  modal,
  tooltip,
  setTooltipContent,
  openTooltip,
  closeTooltip,
  getServicesList,
  getServicesStats,
  getServicesTeams,
  getUnitHistory,
  resetUnitHistory,
  getServiceColumns,
  getServicesAvailableStatus,
  updateServicesStatus
}) => {
  const [notesUpdated, setNotesUpdated] = useState(new Date())

  const prevContext = usePrevious(context)
  const prevServices = usePrevious(services)

  const { pdbid } = user.user_metadata
  const { statsHasData, listHasRequested } = services

  const {
    cService,
    currentPhase,
    forceListRequest,
    contextChanged,
    serviceChanged,
    phaseChanged,
    successfulUpdate
  } = useMemo(() => {
    const {
      currentPhase,
      currentService,
      forceListRequest,
      updateIsRequesting,
      updateIsError
    } = services
    let cService = currentService
    if (cService === 'Concrete/Vinyl Replace') {
      cService = (cService || '').toString().replace('/', '%20')
    }

    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged

    const serviceChanged =
      _.get(prevServices, 'currentService') !== currentService
    const phaseChanged = _.get(prevServices, 'currentPhase') !== currentPhase

    const successfulUpdate =
      _.get(prevServices, 'updateIsRequesting') !== updateIsRequesting &&
      updateIsRequesting === false &&
      updateIsError === false

    return {
      cService,
      forceListRequest,
      contextChanged,
      serviceChanged,
      phaseChanged,
      successfulUpdate,
      currentPhase
    }
  }, [services, context, auth, user])

  // useEffect(() => {
  //   populateData()
  // }, [])

  // useEffect(() => {
  //   populateData()
  // }, [notesUpdated])

  // useEffect(() => {
  // const {
  //   listHasRequested,
  //   statsHasRequested,
  //   currentPhase,
  //   currentService,
  //   forceListRequest,
  //   updateIsRequesting,
  //   updateIsError,
  //   teamsHasRequested
  // } = services
  // let cService = currentService
  // if (cService === 'Concrete/Vinyl Replace') {
  //   cService = (cService || '').toString().replace('/', '%20')
  // }
  // const { isAuthenticated, hasMgmtToken } = auth
  // const { pdbid } = user.user_metadata
  // const propertyChanged =
  //   _.get(context, 'property') !== _.get(prevContext, 'property')
  // const projectChanged =
  //   _.get(context, 'project') !== _.get(prevContext, 'project')
  // const contextChanged = propertyChanged || projectChanged

  // const serviceChanged =
  //   _.get(prevServices, 'currentService') !== currentService
  // const phaseChanged = _.get(prevServices, 'currentPhase') !== currentPhase

  // const successfulUpdate =
  //   _.get(prevServices, 'updateIsRequesting') !== updateIsRequesting &&
  //   updateIsRequesting === false &&
  //   updateIsError === false
  // const readyForAPI = isAuthenticated && hasMgmtToken && pdbid !== null
  // if (readyForAPI) {
  //   if (!listHasRequested) {
  //     getData(pdbid, context, cService, currentPhase)
  //   }
  //   if (!teamsHasRequested)
  //     getServicesTeams(pdbid, context, cService, currentPhase)
  //   if (!statsHasRequested) getServicesStats(pdbid, context, cService)
  // }
  // if (phaseChanged || serviceChanged || successfulUpdate || contextChanged) {
  //   const skipStats = phaseChanged && !serviceChanged
  //   const skipList = successfulUpdate
  //   const skipTeams =
  //     ((!serviceChanged && !phaseChanged) || successfulUpdate) &&
  //     !contextChanged
  //   populateData(skipStats, skipList, skipTeams)
  // }
  // }, [services, context, auth, user, notesUpdated])

  // const populateData = async (
  //   skipStats = false,
  //   skipList = false,
  //   skipTeams = false
  // ) => {
  //   const { isAuthenticated } = auth
  //   const { pdbid } = user.user_metadata
  //   const { currentPhase, currentService, forceListRequest } = services
  //   let cService = currentService
  //   if (cService === 'Concrete/Vinyl Replace') {
  //     cService = (cService || '').toString().replace('/', '%20')
  //   }
  //   if (isAuthenticated && pdbid !== null) {
  //     if (!skipTeams) getServicesTeams(pdbid, context, cService, currentPhase)
  //     if (!skipList || forceListRequest) {
  //       getData(pdbid, context, cService, currentPhase)
  //     }
  //     if (!skipStats) getServicesStats(pdbid, context, cService)
  //   }
  // }

  const getData = async (pdbid, context, cService, currentPhase) => {
    await getServiceColumns({
      pdbid,
      context,
      onSuccess: async columns => {
        await getServicesList(pdbid, context, cService, currentPhase, columns)
      }
    })
  }

  // get teams
  useEffect(() => {
    const skipTeams =
      ((!serviceChanged && !phaseChanged) || successfulUpdate) &&
      !contextChanged

    if (!skipTeams && pdbid) {
      getServicesTeams(pdbid, context, cService, currentPhase)
    }
  }, [
    serviceChanged,
    phaseChanged,
    successfulUpdate,
    contextChanged,
    pdbid,
    context,
    cService,
    currentPhase,
    notesUpdated
  ])

  // get services list
  useEffect(() => {
    if ((!successfulUpdate || forceListRequest) && pdbid) {
      getData(pdbid, context, cService, currentPhase)
    }
  }, [
    successfulUpdate,
    pdbid,
    context,
    cService,
    currentPhase,
    forceListRequest,
    // notesUpdated,
    contextChanged
  ])

  // get services stats
  useEffect(() => {
    if (phaseChanged || serviceChanged || successfulUpdate || contextChanged) {
      const skipStats = phaseChanged && !serviceChanged
      if (!skipStats && pdbid) {
        getServicesStats(pdbid, context, cService)
        getServicesAvailableStatus(pdbid, context, cService)
      }
    }
  }, [phaseChanged, serviceChanged, successfulUpdate, contextChanged])

  return (
    <div>
      <ServicesMain
        context={context}
        user={user}
        services={services}
        responsive={responsive}
        statsHasData={statsHasData}
        listHasRequested={listHasRequested}
        setModalContent={setModalContent}
        openModal={openModal}
        closeModal={closeModal}
        updateNotes={() => setNotesUpdated(new Date())}
        setCurrentPhase={setCurrentPhase}
        setCurrentService={setCurrentService}
        location={location}
        history={history}
        markCompleteService={markCompleteService}
        unblockService={unblockService}
        updateServices={updateServices}
        modal={modal}
        tooltip={tooltip}
        setTooltipContent={setTooltipContent}
        openTooltip={openTooltip}
        closeTooltip={closeTooltip}
        getUnitHistory={getUnitHistory}
        resetUnitHistory={resetUnitHistory}
        getServiceColumns={getServiceColumns}
        updateServicesStatus={updateServicesStatus}
      />
    </div>
  )
}

Services.propTypes = {
  context: PropTypes.object,
  auth: PropTypes.object,
  user: PropTypes.object,
  services: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  history: PropTypes.object,
  responsive: PropTypes.object,
  getServicesList: PropTypes.func,
  getServicesStats: PropTypes.func,
  getServicesTeams: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setModalContent: PropTypes.func,
  setCurrentPhase: PropTypes.func,
  setCurrentService: PropTypes.func,
  markCompleteService: PropTypes.func,
  unblockService: PropTypes.func,
  updateServices: PropTypes.func,
  getUnitHistory: PropTypes.func,
  resetUnitHistory: PropTypes.func,
  getServiceColumns: PropTypes.func,
  getServicesAvailableStatus: PropTypes.func,
  updateServicesStatus: PropTypes.func
}

const mapStateToProps = ({
  context,
  auth,
  user,
  services,
  responsive,
  modal,
  tooltip
}) => ({
  context,
  auth,
  user,
  services,
  responsive,
  modal,
  tooltip
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getServicesList,
      getServicesStats,
      getServicesTeams,
      setModalContent,
      openModal,
      closeModal,
      setTooltipContent,
      openTooltip,
      closeTooltip,
      setCurrentPhase,
      setCurrentService,
      markCompleteService,
      unblockService,
      updateServices,
      getUnitHistory,
      resetUnitHistory,
      getServiceColumns,
      getServicesAvailableStatus,
      updateServicesStatus
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Services)
)
