import Service from '../service'
import LOGOUT from './auth'
import moment from 'moment'

/**
 * Notes module
 * @redux
 * @reduxActionScope notes
 * @module notes
 */

/**
 * Reset notes data
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_RESET = 'notes/UNIT_NOTES_RESET'

/**
 * Request notes for a specific unit
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_GET_REQUESTED = 'notes/UNIT_NOTES_GET_REQUESTED'
/**
 * Notes fetch for a specific failed
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_GET_FAIL = 'notes/UNIT_NOTES_GET_FAIL'
/**
 * Update notes for a specific unit with successful data from API
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_GET_SUCCESS = 'notes/UNIT_NOTES_GET_SUCCESS'

/**
 * Request create a new note for a specific unit
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_CREATE_REQUESTED = 'notes/UNIT_NOTES_CREATE_REQUESTED'
/**
 * Note creation failed
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_CREATE_FAIL = 'notes/UNIT_NOTES_CREATE_FAIL'
/**
 * Update notes after a note was successfully added by the API
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_CREATE_SUCCESS = 'notes/UNIT_NOTES_CREATE_SUCCESS'

/**
 * Request update a note
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_UPDATE_REQUESTED = 'notes/UNIT_NOTES_UPDATE_REQUESTED'
/**
 * Note update failed
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_UPDATE_FAIL = 'notes/UNIT_NOTES_UPDATE_FAIL'
/**
 * Refresh notes after a note was successfully updated by the API
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_UPDATE_SUCCESS = 'notes/UNIT_NOTES_UPDATE_SUCCESS'

/**
 * Request delete a note
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_DELETE_REQUESTED = 'notes/UNIT_NOTES_DELETE_REQUESTED'
/**
 * Note delete failed
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_DELETE_FAIL = 'notes/UNIT_NOTES_DELETE_FAIL'
/**
 * Update notes after a note was successfully deleted by the API
 * @type {Redux.ActionType}
 */
export const UNIT_NOTES_DELETE_SUCCESS = 'notes/UNIT_NOTES_DELETE_SUCCESS'

export const ALL_UNIT_NOTES_GET_REQUESTED = 'ALL_UNIT_NOTES_GET_REQUESTED'
export const ALL_UNIT_NOTES_GET_FAIL = 'ALL_UNIT_NOTES_GET_FAIL'
export const ALL_UNIT_NOTES_GET_SUCCESS = 'ALL_UNIT_NOTES_GET_SUCCESS'
export const RESET_ALL_UNITS_NOTES = 'notes/RESET_ALL_UNITS_NOTES'
export const RESET_SINGLE_NOTE = 'notes/RESET_SINGLE_NOTE'

const DN = ''
const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isRequestingUpdate: false,
  isError: false,
  isErrorUpdate: false,
  data: [],
  allNotes: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isRequestingUpdate: false,
    isError: false,
    isErrorUpdate: false,
    data: []
  }
}

/**
 * Notes for a unit reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case UNIT_NOTES_RESET:
      return initialState
    case UNIT_NOTES_GET_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case ALL_UNIT_NOTES_GET_REQUESTED:
      return {
        ...state,
        allNotes: {
          ...state.allNotes,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case ALL_UNIT_NOTES_GET_SUCCESS:
      return {
        ...state,
        allNotes: {
          ...state.allNotes,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case RESET_ALL_UNITS_NOTES:
      return {
        ...state,
        allNotes: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isRequestingUpdate: false,
          isError: false,
          isErrorUpdate: false,
          data: []
        }
      }
    case RESET_SINGLE_NOTE:
      return {
        ...state,
        hasMadeInitialRequest: false,
        isRequesting: false,
        isRequestingUpdate: false,
        isError: false,
        isErrorUpdate: false,
        data: []
      }
    case ALL_UNIT_NOTES_GET_FAIL:
      return {
        ...state,
        allNotes: {
          ...state.allNotes,
          isRequesting: false,
          isError: true,
          data: action.data
        }
      }
    case UNIT_NOTES_GET_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        data: action.data
      }
    case UNIT_NOTES_GET_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case UNIT_NOTES_CREATE_REQUESTED:
    case UNIT_NOTES_UPDATE_REQUESTED:
    case UNIT_NOTES_DELETE_REQUESTED:
      return {
        ...state,
        isRequestingUpdate: true,
        isErrorUpdate: false
      }
    case UNIT_NOTES_CREATE_SUCCESS:
    case UNIT_NOTES_UPDATE_SUCCESS:
    case UNIT_NOTES_DELETE_SUCCESS:
      return {
        ...state,
        isRequestingUpdate: false,
        isErrorUpdate: false
        // data: action.data
      }
    case UNIT_NOTES_CREATE_FAIL:
    case UNIT_NOTES_UPDATE_FAIL:
    case UNIT_NOTES_DELETE_FAIL:
      return {
        ...state,
        isRequestingUpdate: false,
        isErrorUpdate: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Resets the notes for a unit
 * @redux
 * @reduxActionCreator UNIT_NOTES_RESET
 */
export const unitNotesReset = () => {
  return dispatch => {
    dispatch({
      type: UNIT_NOTES_RESET
    })
  }
}

/**
 * Gets all notes for all units
 * @redux
 * @reduxActionCreator ALL_UNIT_NOTES_GET_REQUESTED, ALL_UNIT_NOTES_GET_SUCCESS, ALL_UNIT_NOTES_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const allNotesGet = (pdbid, context, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: ALL_UNIT_NOTES_GET_REQUESTED
    })
    Service.allNotesGet(pdbid, context)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: ALL_UNIT_NOTES_GET_SUCCESS,
            data: _allUnitNotesAdapter(data)
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch({
            type: ALL_UNIT_NOTES_GET_FAIL,
            data: []
          })
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        dispatch({
          type: ALL_UNIT_NOTES_GET_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const updateAllNotesGetByState = () => {
  return dispatch => {
    dispatch({
      type: RESET_ALL_UNITS_NOTES
    })
  }
}

export const resetSingleNoteGET = () => {
  return dispatch => {
    dispatch({
      type: RESET_SINGLE_NOTE
    })
  }
}

export const resetAllNoteGET = () => {
  return dispatch => {
    dispatch({
      type: RESET_ALL_UNITS_NOTES
    })
  }
}

/**
 * Gets all notes for a single unit
 * @redux
 * @reduxActionCreator UNIT_NOTES_GET_REQUESTED, UNIT_NOTES_GET_SUCCESS, UNIT_NOTES_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unitId - the id for the unit requested
 */
export const unitNotesGet = (
  pdbid,
  context,
  unitId,
  onNoteGetSuccess,
  onNoteGetError
) => {
  return dispatch => {
    dispatch({
      type: UNIT_NOTES_GET_REQUESTED
    })
    Service.notesGet(pdbid, context, unitId)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UNIT_NOTES_GET_SUCCESS,
            data: _unitNotesAdapter(data)
          })
          if (onNoteGetSuccess) {
            onNoteGetSuccess()
          }
        } else {
          _unitNotesGetFail(dispatch)
          if (onNoteGetError) {
            onNoteGetError()
          }
        }
      })
      .catch(() => {
        _unitNotesGetFail(dispatch)
        if (onNoteGetError) {
          onNoteGetError()
        }
      })
  }
}

/**
 * Gets all notes for a single unit
 * @redux
 * @reduxActionCreator UNIT_NOTES_GET_REQUESTED, UNIT_NOTES_GET_SUCCESS, UNIT_NOTES_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unitId - the id for the unit requested
 */
export const getUnitServiceNotes = (
  pdbid,
  context,
  unitId,
  serviceTypeId,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: UNIT_NOTES_GET_REQUESTED
    })
    Service.getUnitServiceNotes(pdbid, context, unitId, serviceTypeId)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UNIT_NOTES_GET_SUCCESS,
            data: _unitNotesAdapter(data)
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          _unitNotesGetFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _unitNotesGetFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

export const getQCVendorUnitServiceNotes = (
  pdbid,
  context,
  unitId,
  serviceTypeId,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: UNIT_NOTES_GET_REQUESTED
    })
    Service.getQCVendorUnitServiceNotes(pdbid, context, unitId, serviceTypeId)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UNIT_NOTES_GET_SUCCESS,
            data: _unitNotesAdapter(data)
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          _unitNotesGetFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _unitNotesGetFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

const _unitNotesGetFail = dispatch => {
  dispatch({
    type: UNIT_NOTES_GET_FAIL
  })
}

const _oneNoteAdapter = data => {
  return {
    uri: typeof data.note_uri === 'string' ? data.note_uri : DN,
    id: typeof data.note_id === 'string' ? data.note_id : DN,
    servicetype_uri:
      typeof data.servicetype_uri === 'string' ? data.servicetype_uri : DN,
    servicetype_id:
      typeof data.servicetype_id === 'string' ? data.servicetype_id : DN,
    vendor_id: typeof data.vendor_id === 'string' ? data.vendor_id : DN,
    purchase_order_id:
      typeof data.purchase_order_id === 'string' ? data.purchase_order_id : DN,
    servicetype: typeof data.servicetype === 'string' ? data.servicetype : DN,
    unit_uri: typeof data.unit_uri === 'string' ? data.unit_uri : DN,
    unit_id: typeof data.unit_id === 'string' ? data.unit_id : DN,
    unit: typeof data.unit === 'string' ? data.unit : DN,
    unit_space: typeof data.unit_space === 'string' ? data.unit_space : DN,
    workorder_uri:
      typeof data.workorder_uri === 'string' ? data.workorder_uri : DN,
    operation_uri:
      typeof data.operation_uri === 'string' ? data.operation_uri : DN,
    type: typeof data.type === 'string' ? data.type : DN,
    reason: typeof data.reason === 'string' ? data.reason : DN,
    note: typeof data.note === 'string' ? data.note : DN,
    inactive: typeof data.inactive === 'string' ? true : false,
    created_by: typeof data.created_by === 'string' ? data.created_by : DN,
    creation_date:
      typeof data.creation_date === 'string'
        ? moment(data.creation_date).format('DD-MMM-YYYY')
        : DN,
    charge: data.charge || DN
  }
}

const _unitNotesAdapter = data => {
  return Array.isArray(data) ? data.map(d => _oneNoteAdapter(d)) : []
}

const _oneAllNoteAdapter = data => {
  return {
    uri: typeof data.note_uri === 'string' ? data.note_uri : DN,
    id: typeof data.note_id === 'string' ? data.note_id : DN,
    servicetype_id:
      typeof data.servicetype_id === 'string' ? data.servicetype_id : DN,
    vendor_id: typeof data.vendor_id === 'string' ? data.vendor_id : DN,
    purchase_order_id:
      typeof data.purchase_order_id === 'string' ? data.purchase_order_id : DN,
    unit_id: typeof data.unit_id === 'string' ? data.unit_id : DN,
    unit: typeof data.unit === 'string' ? data.unit : DN,
    workorder_uri:
      typeof data.workorder_uri === 'string' ? data.workorder_uri : DN,
    type: typeof data.type === 'string' ? data.type : DN,
    reason: typeof data.reason === 'string' ? data.reason : DN,
    note: typeof data.note === 'string' ? data.note : DN,
    inactive: typeof data.inactive === 'string' ? true : false,
    created_by: typeof data.created_by === 'string' ? data.created_by : DN,
    creation_date:
      typeof data.creation_date === 'string'
        ? moment(data.creation_date).format('DD-MMM-YYYY')
        : DN,
    charge: data.charge || DN
  }
}

const _allUnitNotesAdapter = data => {
  return Array.isArray(data) ? data.map(d => _oneAllNoteAdapter(d)) : []
}

/**
 * Create a new note for a unit
 * @redux
 * @reduxActionCreator UNIT_NOTES_CREATE_REQUESTED, UNIT_NOTES_CREATE_SUCCESS, UNIT_NOTES_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unitId - the id for the unit requested
 * @param {Object} body - the JSON element for the new note
 */
export const unitNotesCreate = ({
  pdbid,
  context,
  unitId,
  body,
  type,
  onSuccess,
  onError,
  notes,}
) => {
  type = type ?? 'service'
  return dispatch => {
    dispatch({
      type: UNIT_NOTES_CREATE_REQUESTED
    })
    Service.notesCreate(pdbid, context, unitId, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (type === 'service') {
            dispatch({
              type: UNIT_NOTES_CREATE_SUCCESS,
              data: notes.data,
              unitId
            })
          }else{
            dispatch({
              type: UNIT_NOTES_CREATE_SUCCESS,
            })
          }

          if (onSuccess) {
            onSuccess()
          }
        } else {
          _unitNotesCreateFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _unitNotesCreateFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

const _unitNotesCreateFail = dispatch => {
  dispatch({
    type: UNIT_NOTES_CREATE_FAIL
  })
}

/**
 * Update a note
 * @redux
 * @reduxActionCreator UNIT_NOTES_UPDATE_REQUESTED, UNIT_NOTES_UPDATE_SUCCESS, UNIT_NOTES_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unitId - the id for of the unit with the note
 * @param {Object} body - the JSON element for the updated note, including the unit id
 */
export const unitNotesUpdate = (
  pdbid,
  context,
  unitId,
  body,
  onSuccess,
  onError,
  type = 'service'
) => {
  return dispatch => {
    dispatch({
      type: UNIT_NOTES_UPDATE_REQUESTED
    })
    Service.notesUpdate(pdbid, context, unitId, body)
      .then(async res => {
        if (res.ok) {
          // const data = await res.json()
          if (type === 'service') {
            dispatch({
              type: UNIT_NOTES_UPDATE_SUCCESS
              // data: _unitNotesAdapter(data),
              // unitId
            })
          }

          if (onSuccess) {
            onSuccess()
          }
        } else {
          _unitNotesUpdateFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _unitNotesUpdateFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

const _unitNotesUpdateFail = dispatch => {
  dispatch({
    type: UNIT_NOTES_UPDATE_FAIL
  })
}

/**
 * Delete a note
 * @redux
 * @reduxActionCreator UNIT_NOTES_DELETE_REQUESTED, UNIT_NOTES_DELETE_SUCCESS, UNIT_NOTES_DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unitId - the id for of the unit with the note
 * @param {Object} body - the JSON element for the note to be deleted, including the unit id
 */
export const unitNotesDelete = (
 { pdbid,
  context,
  unitId,
  body,
  notes,
  onSuccess,
  onError,
  type }
) => {

  type = type ?? 'service'
  return dispatch => {
    dispatch({
      type: UNIT_NOTES_DELETE_REQUESTED
    })
    Service.notesDelete(pdbid, context, unitId, body)
      .then(async res => {
        if (res.ok) {
          // const data = await res.json()
          if (type === 'service') {
            dispatch({
              type: UNIT_NOTES_DELETE_SUCCESS,
              data: notes.data,
                  unitId
            })
          }
          else{
            dispatch({
              type: UNIT_NOTES_DELETE_SUCCESS,
            })
          }
          if (onSuccess) {
            onSuccess()
          }
        } else {
          _unitNotesDeleteFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(e => {
        _unitNotesDeleteFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

const _unitNotesDeleteFail = dispatch => {
  dispatch({
    type: UNIT_NOTES_DELETE_FAIL
  })
}
