import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ui/icons'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'
import { getIncompleteUnitSpaces, getServicesByProperty } from '../../modules/sidebar'
import { useDispatch } from 'react-redux'

const ServicesModalApproveAll = ({
  rows,
  closeModal,
  updateServices,
  user,
  action,
  servicesData,
  context
}) => {
  const dispatch = useDispatch()
  const { services } = useSelector(state => state.sidebar)
  const { pdbid } = user.user_metadata
  const {properties} = user.user_metadata
  const currentProperty = properties.find(p => p.id === context.property)

  const serviceColumns =
    [...(servicesData?.serviceColumns || []), { unitspace: 'Common' }] || {}
  const { currentService } = servicesData || {}

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleApproveAll = () => {
    const items = (rows || []).map(row => {
      const serviceFound = (services || []).find(
        service => service.servicetype_id === row.servicetype_id
      )

      const task_uri = (serviceColumns || [])
        .map(({ unitspace }) => row[unitspace]?.task_uri || '')
        .filter(task_uri => !isEmpty(task_uri))
        .join(',')

      return {
        service: serviceFound?.name || '',
        servicetype_id: row?.servicetype_id || '',
        team_uri: row?.team_uri || '',
        operation_uri: row?.operation_uri || '',
        unit: row?.unit || '',
        unit_id: row?.unit_id || '',
        unit_uri: row?.unit_uri || '',
        workorder: row?.workorder || '',
        qc_team_uri: row?.qc_team_uri || '',
        goback_spaces: row?.goback_spaces || '',
        goback_notes: row?.goback_notes || '',
        task_uri,
        goback_task_uri: row?.goback_task_uri || ''
      }
    })

    const body = { items }

    setLoading(true)
    setError('')

    const onSuccess = () => {
      setLoading(false)
      closeModal(true)
      dispatch(getIncompleteUnitSpaces(pdbid,context, currentProperty.role.id))
    }

    const onError = () => {
      setError('Something went wrong. Please try again later !!!')
      setLoading(false)
    }

    updateServices(
      pdbid,
      context,
      currentService?.replace('/', '%20'),
      action,
      body,
      onSuccess,
      onError,
      false
    )
  }

  return (
    <>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Approve</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModal}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6 sub-message">
            You are about to approve all the units that you have selected. Are
            you sure you want to proceed?
          </p>
          {error && (
            <ErrorMessage>
              <p className="is-size-6 sub-message">{error}</p>
            </ErrorMessage>
          )}
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={closeModal}>
            Cancel
          </button>
          <button
            className="button main-button is-primary"
            onClick={handleApproveAll}>
            {loading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Approve
          </button>
        </div>
      </div>
    </>
  )
}

export default ServicesModalApproveAll
