import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { isBlacklisted, getIsActive } from '../sidebarUtilities'
import { resetInvoiceDetails } from 'modules/invoices'
import { FlagFeaturePermissionGate } from '../../../helpers/hooks'

import {
  INCOMPLETED_UNITSPACES_FAIL,
  INCOMPLETED_UNITSPACES_REQUSTED,
  INCOMPLETED_UNITSPACES_SUCCESS,
  INCOMPLETED_UNITSPACES_INITIAL
} from '../../../modules/sidebar'

export const DropdownItem = ({
  menuItem,
  currentPath,
  onClick,
  user,
  property,
  loading,
  incompletedUnitspacesStatus,
  incompletedUnitspaces
}) => {
  const dispatch = useDispatch()
  const [isCollapsed, setIsCollapsed] = React.useState(true)
  const filteredChildren = menuItem.children
  // .filter(
  //   item => !isBlacklisted(user, item, property)
  // )

  if (filteredChildren.length === 0) {
    return null
  }

  const featureGates = {
    Services: 'SERVICES_BUBBLE',
    Walk: 'WALK_BUBBLE',
    Holdovers: 'WALK_BUBBLE'
  }

  const featureName = featureGates[menuItem.name]

  return (
    <li>
      <div
        onClick={() => {
          if (!loading) {
            setIsCollapsed(!isCollapsed)
          }
        }}
        className={`collapser has-text-white is-unselectable is-pointer is-block ${
          isCollapsed && menuItem.shouldHighlight(currentPath)
            ? 'has-background-primary'
            : ''
        }`}>
        <div className="columns is-desktop is-mobile is-vcentered is-gapless">
          <div className="column is-narrow menu-icon">
            <img
              src={menuItem.iconSrc}
              className="is-inline"
              alt={menuItem.altText}
            />
          </div>
          <div className="column has-text-weight-bold">
            {menuItem.name}
            <FontAwesomeIcon
              icon={loading ? faSpinner : faAngleDown}
              spin={loading}
              className={`is-pulled-right down-arrow ${
                isCollapsed && !loading ? '' : 'is-upsidedown'
              }`}
            />
          </div>
        </div>
      </div>
      <ul
        className={`is-borderless is-paddingless is-marginless ${
          isCollapsed ? 'is-hidden' : ''
        }`}>
        {filteredChildren.map((childItem, i) => {
          return (
            <li key={i} style={{ marginLeft: '0.5rem' }}>
              <Link
                to={childItem.path}
                onClick={() => {
                  onClick()
                  if (childItem?.name?.toLowerCase() === 'purchase orders') {
                    dispatch(resetInvoiceDetails())
                  }
                }}
                className={`has-text-white ${getIsActive(
                  currentPath,
                  childItem.path
                )}`}>
                {featureName && (
                  <FlagFeaturePermissionGate name={featureName}>
                    <Tag
                      incompletedUnitspacesStatus={incompletedUnitspacesStatus}
                      incompletedUnitspaces={incompletedUnitspaces}
                      childItem={childItem.name}
                    />
                  </FlagFeaturePermissionGate>
                )}

                <span className="services-option">{childItem.name}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

export const Tag = ({
  incompletedUnitspacesStatus,
  incompletedUnitspaces,
  childItem
}) => {
  if (incompletedUnitspacesStatus == INCOMPLETED_UNITSPACES_REQUSTED) {
    return (
      <span className="badge">
        <FontAwesomeIcon icon={faSpinner} spin size="1x" />
      </span>
    )
  } else if (incompletedUnitspacesStatus == INCOMPLETED_UNITSPACES_SUCCESS) {
    const tagValue = findServiceByName(childItem, incompletedUnitspaces)
    if (tagValue >= 9999) {
      return (
        <span className="badge">
          {tagValue.toString().substring(0, 4) + '...'}{' '}
          <span class="tooltip-badge">
            {findServiceByName(childItem, incompletedUnitspaces)}
          </span>{' '}
        </span>
      )
    } else if (tagValue == 0) return null

    return <span className="badge">{tagValue} </span>
  }
  return null
}

function findServiceByName(serviceName, incompletedUnitspaces) {
  const service = incompletedUnitspaces.services.find(
    s => s.service === serviceName
  )
  return service ? service.value : null
}
