import Service from '../service'
import { keysIcons } from '../parents/keys-main/keys-icons'
import LOGOUT from './auth'
import CONTEXT_UPDATE from './context'
import {getIncompleteUnitSpaces} from './sidebar'

/**
 * Keys data module
 * @redux
 * @reduxActionScope keys
 * @module keys
 */

/**
 * Request keys list
 * @type {Redux.ActionType}
 */
export const KEYS_LIST_REQUESTED = 'keys/KEYS_LIST_REQUESTED'
/**
 * Keys list failed
 * @type {Redux.ActionType}
 */
export const KEYS_LIST_FAIL = 'keys/KEYS_LIST_FAIL'
/**
 * Update requested keys list with successful data from API
 * @type {Redux.ActionType}
 */
export const KEYS_LIST_SUCCESS = 'keys/KEYS_LIST_SUCCESS'

/**
 * Request keys stats
 * @type {Redux.ActionType}
 */
export const KEYS_STATS_REQUESTED = 'keys/KEYS_STATS_REQUESTED'
/**
 * Keys stats failed
 * @type {Redux.ActionType}
 */
export const KEYS_STATS_FAIL = 'keys/KEYS_STATS_FAIL'
/**
 * Update requested keys stats with successful data from API
 * @type {Redux.ActionType}
 */
export const KEYS_STATS_SUCCESS = 'keys/KEYS_STATS_SUCCESS'

export const GET_KEYS_SYNC_REQUESTED = 'keys/GET_KEYS_SYNC_REQUESTED'
export const GET_KEYS_SYNC_SUCCESS = 'keys/GET_KEYS_SYNC_SUCCESS'
export const GET_KEYS_SYNC_FAIL = 'keys/GET_KEYS_SYNC_FAIL'

export const DO_SYNC_KEYS_REQUESTED = 'keys/DO_SYNC_KEYS_REQUESTED'
export const DO_SYNC_KEYS_SUCCESS = 'keys/DO_SYNC_KEYS_SUCCESS'
export const DO_SYNC_KEYS_FAIL = 'keys/DO_SYNC_KEYS_FAIL'

/**
 * Request keys status update
 * @type {Redux.ActionType}
 */
export const KEYS_UPDATE_REQUESTED = 'keys/KEYS_UPDATE_REQUESTED'
/**
 * Keys status update failed
 * @type {Redux.ActionType}
 */
export const KEYS_UPDATE_FAIL = 'keys/KEYS_UPDATE_FAIL'
/**
 * Update keys after successful status update from API
 * @type {Redux.ActionType}
 */
export const KEYS_UPDATE_SUCCESS = 'keys/KEYS_UPDATE_SUCCESS'

const initialStats = {
  expected: 0,
  returned: 0,
  potential_holdovers: 0,
  holdovers: 0,
  rto: 0,
  rti: 0,
  leapfrogs: 0,
  total_beds: 0,
  total_renewed: 0,
  total_vacant: 0
}

const initialKeysSyncState = {
  doSyncKeysRequested: false,
  keysSyncRequested: false,
  keysSyncError: false,
  keysSource: '',
  lastSync: null,
  status: null,
  errorMessage: ''
}

const initialState = {
  listHasRequested: false,
  listIsRequesting: false,
  listIsError: false,
  list: [],
  statsHasRequested: false,
  statsIsRequesting: false,
  statsIsError: false,
  stats: initialStats,
  updateIsRequesting: false,
  updateIsError: false,
  keysSyncState: initialKeysSyncState
}

/**
 * Keys data reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case KEYS_LIST_REQUESTED:
      return {
        ...state,
        listIsRequesting: true,
        listIsError: false
      }
    case KEYS_LIST_SUCCESS:
      return {
        ...state,
        listHasRequested: true,
        listIsRequesting: false,
        listIsError: false,
        list: action.list
      }
    case KEYS_LIST_FAIL:
      return {
        ...state,
        listHasRequested: true,
        listIsRequesting: false,
        listIsError: true
      }
    case KEYS_STATS_REQUESTED:
      return {
        ...state,
        statsIsRequesting: true,
        statsIsError: false
      }
    case KEYS_STATS_SUCCESS:
      return {
        ...state,
        statsHasRequested: true,
        statsIsRequesting: false,
        statsIsError: false,
        stats: action.stats
      }
    case KEYS_STATS_FAIL:
      return {
        ...state,
        statsHasRequested: true,
        statsIsRequesting: false,
        statsIsError: true
      }
    case KEYS_UPDATE_REQUESTED:
      return {
        ...state,
        updateIsRequesting: true,
        updateIsError: false
      }
    case KEYS_UPDATE_SUCCESS:
      return {
        ...state,
        updateIsRequesting: false,
        updateIsError: false
      }
    case KEYS_UPDATE_FAIL:
      return {
        ...state,
        updateIsRequesting: false,
        updateIsError: true
      }
    case GET_KEYS_SYNC_REQUESTED:
      return {
        ...state,
        keysSyncState: {
          ...state.keysSyncState,
          keysSyncRequested: true,
          keysSyncError: false
        }
      }
    case GET_KEYS_SYNC_SUCCESS:
      return {
        ...state,
        keysSyncState: {
          ...state.keysSyncState,
          keysSyncRequested: false,
          keysSyncError: false,
          keysSource: action.source,
          lastSync: action.date,
          status: action.status,
          errorMessage:
            action.errorMessage && action.errorMessage.length > 0
              ? action.errorMessage
                  .split(' ')
                  .map((item, index) => {
                    if (index % 5 === 0 && index > 0) {
                      return `${item}\n`
                    }

                    return `${item}\xa0`
                  })
                  .join('')
              : action.errorMessage
        }
      }
    case GET_KEYS_SYNC_FAIL:
      return {
        ...state,
        keysSyncState: {
          ...state.keysSyncState,
          keysSyncRequested: false,
          keysSyncError: true
        }
      }

    case CONTEXT_UPDATE:
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets keys list
 * @redux
 * @reduxActionCreator KEYS_LIST_REQUESTED, KEYS_LIST_SUCCESS, KEYS_LIST_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getKeysList = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: KEYS_LIST_REQUESTED
    })
    Service.getKeysList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const list = await res.json()
          dispatch({
            type: KEYS_LIST_SUCCESS,
            list: _listAdapter(list)
          })
        } else {
          dispatch({
            type: KEYS_LIST_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: KEYS_LIST_FAIL
        })
      })
  }
}

/**
 * Gets keys stats
 * @redux
 * @reduxActionCreator KEYS_STATS_REQUESTED, KEYS_STATS_SUCCESS, KEYS_STATS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getKeysStats = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: KEYS_STATS_REQUESTED
    })
    Service.getKeysStats(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: KEYS_STATS_SUCCESS,
            stats: _statsAdapter(data)
          })
        } else {
          dispatch({
            type: KEYS_STATS_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: KEYS_STATS_FAIL
        })
      })
  }
}

/**
 * Get Keys sync status
 * @redux
 * @reduxActionCreator GET_KEYS_SYNC_REQUESTED, GET_KEYS_SYNC_SUCCESS, GET_KEYS_SYNC_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getKeysSyncData = (pdbid, context) => {
  return dispatch => {
    Service.getKeysSyncData(pdbid, context).then(async res => {
      if (res.ok) {
        try {
          const data = await res.json()
          const statusData = data?.items?.[0]

          dispatch({
            type: GET_KEYS_SYNC_SUCCESS,
            source: statusData.inspect_source,
            date: statusData.last_updated
              ? new Date(statusData.last_updated)
              : new Date(),
            status: statusData.status || 'complete',
            errorMessage: statusData.error_message
          })
        } catch (err) {
          dispatch({
            type: GET_KEYS_SYNC_FAIL
          })
        }
      }
    })
  }
}

/**
 * Get Keys sync status
 * @redux
 * @reduxActionCreator
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Function} onSuccess - the success callback
 * @param {Function} onError - the error callback
 */
export const doSyncKeysData = (pdbid, context, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: GET_KEYS_SYNC_REQUESTED
    })
    Service.doSyncKeysData(pdbid, context)
      .then(async res => {
        if (res.ok) {
          onSuccess && onSuccess()
          return
        }

        onError && onError()
      })
      .catch(() => {
        onError && onError()
      })
  }
}

/**
 * Updates room status
 * @redux
 * @reduxActionCreator KEYS_UPDATE_REQUESTED, KEYS_UPDATE_SUCCESS, KEYS_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unit - the unit id
 * @param {string} roomKey - the room key id
 * @param {string} status - the desired new status
 */
export const updateKey = (pdbid, context, unit, roomKey, status, role) => {
  return dispatch => {
    dispatch({
      type: KEYS_UPDATE_REQUESTED
    })
    Service.updateKey(pdbid, context, unit, roomKey, status)
      .then(async res => {
        if (res.ok) {
          //const data = await res.json()
          dispatch({
            type: KEYS_UPDATE_SUCCESS
          })
        } else {
          dispatch({
            type: KEYS_UPDATE_FAIL
          })
        }
        dispatch(getIncompleteUnitSpaces(pdbid,context, role))
      })
      .catch(() => {
        dispatch({
          type: KEYS_UPDATE_FAIL
        })
      })
  }
}

const DEFAULT_LIST_VALUE = ''
const _getIcon = us => {
  if (us.status === 'Yes') {
    if (us.tentant_status === 'RTI') return keysIcons.rTIGreen
    else if (us.tentant_status === 'RTO') return keysIcons.rTOGreen
    else if (us.tentant_status === 'Leapfrog') return keysIcons.leapfrogsGreen
    else return keysIcons.returned
  } else if (us.status === 'R') return keysIcons.renewal
  else if (us.status === 'V') {
    if (us.tentant_status === 'RTI') return keysIcons.rTIGreen
    else if (us.tentant_status === 'Leapfrog') return keysIcons.leapfrogsGreen
    else return keysIcons.vacant
  } else if (us.status === 'holdover') return keysIcons.holdover
  else if (us.status === 'No') {
    if (us.tentant_status === 'RTI') return keysIcons.rTI
    else if (us.tentant_status === 'RTO') return keysIcons.rTO
    else if (us.tentant_status === 'Leapfrog') return keysIcons.leapfrogs
    else return keysIcons.expected
  } else return keysIcons.expected
}
const _getUnitSpaces = (unitSpaces, row) => {
  const uniqueUnits = new Set()
  let obj = {}

  unitSpaces.forEach(item => {
    return uniqueUnits.add(item.unitspace)
  })
  const arrUnits = Array.from(uniqueUnits)

  const result = arrUnits.map(room => {
    const check = unitSpaces.filter(it => it.unitspace.indexOf(room) !== -1)

    const multiple = check.length > 1

    return {
      room,
      multiple,
      data: multiple ? check : check[0]
    }
  })

  for (let i = 0; i < result.length; i += 1) {
    let us = result[i].data

    let room =
      typeof us.unitspace === 'string'
        ? us.unitspace.toLowerCase()
        : DEFAULT_LIST_VALUE

    let newus = {}

    if (result[i].multiple) {
      room = result[i].room.toLowerCase()
      newus = {
        multiple: true,
        data: us.map(item => {
          return {
            ...item,
            icon: _getIcon(item),
            tooltip: {
              unit:
                typeof row.unit === 'string' ? row.unit : DEFAULT_LIST_VALUE,
              room: item.unitspace,
              returned_date:
                typeof item.returned_date === 'string' && item.status !== 'No'
                  ? item.returned_date
                  : DEFAULT_LIST_VALUE,
              resident:
                typeof item.resident === 'string'
                  ? item.resident
                  : DEFAULT_LIST_VALUE
            }
          }
        })
      }
    } else {
      newus = {
        ...us,
        icon: _getIcon(us),
        tooltip: {
          unit: typeof row.unit === 'string' ? row.unit : DEFAULT_LIST_VALUE,
          room,
          returned_date:
            typeof us.returned_date === 'string' && us.status !== 'No'
              ? us.returned_date
              : DEFAULT_LIST_VALUE,
          resident:
            typeof us.resident === 'string' ? us.resident : DEFAULT_LIST_VALUE
        }
      }
    }
    obj[room] = typeof us !== 'undefined' ? newus : null
  }
  return obj
}
const _alphabetically = (a, b) => {
  const x = a.unitspace.toLowerCase()
  const y = b.unitspace.toLowerCase()
  if (x < y) {
    return -1
  }
  if (x > y) {
    return 1
  }
  return 0
}
const _listAdapter = data => {
  let list = []
  for (let i = 0; i < data.length; i += 1) {
    const d = data[i]
    const sorted = d.UnitSpaces.sort(_alphabetically)
    list.push({
      unit: d.unit || DEFAULT_LIST_VALUE,
      unit_id: d.unit_id || DEFAULT_LIST_VALUE,
      returns: (d.returns && d.returns.split('/')[0]) || DEFAULT_LIST_VALUE,
      expected: (d.returns && d.returns.split('/')[1]) || DEFAULT_LIST_VALUE,
      unitSpaces: Array.isArray(d.UnitSpaces) ? _getUnitSpaces(sorted, d) : [],
      has_note: typeof d.has_note === 'string'
    })
  }
  return list
}

const DEFAULT_VALUE = 0
const _statsAdapter = data => {
  let stats = initialStats
  const { items } = data
  if (Array.isArray(items) && items.length > 0) {
    const d = items[0]
    stats = {
      expected: d.total_expected || DEFAULT_VALUE,
      returned: d.total_returned || DEFAULT_VALUE,
      holdovers: d.holdover || DEFAULT_VALUE,
      potential_holdovers: d.potential_holdover || DEFAULT_VALUE,
      rto: d.total_rto || DEFAULT_VALUE,
      rti: d.total_rti || DEFAULT_VALUE,
      leapfrogs: d.total_leapfrog || DEFAULT_VALUE,
      total_beds: d.total_beds || DEFAULT_VALUE,
      total_renewed: d.total_renewed || DEFAULT_VALUE,
      total_vacant: d.total_vacant || DEFAULT_VALUE,
      days_remaining: d.days_remaining || DEFAULT_VALUE,
      percent_complete: d.percent_complete || DEFAULT_VALUE
    }
  }
  return stats
}
