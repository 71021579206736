import _ from 'lodash'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import Breadcrumbs from '../../parents/breadcrumbs'
import KeysStats from '../keys-stats'
import KeysModal from '../keys-modal'
import DataTable from '../../components/data-table'
// import { keysIcons } from './keys-icons'
import { headerData } from './header-data'
import Notes from '../../containers/notes'
import iconQuestionCircle from './icons/question_circle.svg'
import alertIcon from './icons/alert.svg'
import checkIcon from './icons/solidgreencheck.svg'
import { sectionManageable } from '../../security'
import { isDescendant } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import KeysSyncModal from './keys-sync-modal'
import { getFormattedDateTime } from '../../utils/helperFunctions'
import './index.scss'
import PermissionGate from '../../components/permission-gate'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getIncompleteUnitSpaces, getServicesByProperty } from '../../modules/sidebar'

const MODAL_WIDTH = '500px'

const KeysMain = ({
  context,
  openModal,
  closeModal,
  setModalContent,
  user,
  auth,
  setTooltipContent,
  openTooltip,
  closeTooltip,
  keys,
  responsive,
  tooltip,
  location,
  updateKey,
  modal,
  services,
  onRefresh,
  onSyncKeys
}) => {
  const dispatch = useDispatch()
  const [unit, setUnit] = useState(null)
  const [room, setRoom] = useState(null)
  const [unitSpace, setUnitspace] = useState(null)
  const [searchBox, setSearchBox] = useState('')
  const [resetPage, setResetPage] = useState(false)
  const [filter, setFilter] = useState(null)
  const [allowEdit, setAllowEdit] = useState(false)
  const [editInitialized, setEditInitialized] = useState(false)
  const [pendingRefresh, setPendingRefresh] = useState(false)

  const prevLocation = usePrevious(location)
  const prevKeys = usePrevious(keys)
  const prevUser = usePrevious(user)
  const prevAuth = usePrevious(auth)

  const morInfoBtn = useRef(null)
  const tableRef = useRef()
  const {properties} = user.user_metadata
  const currentProperty = _.find(properties, p => p.id === context.property)

  const { keysSyncState } = keys || {}

  useEffect(() => {
    const { search } = location
    const s = search.substr(1, search.length - 1)
    const params = _.map(s.split('&'), param => ({
      key: param.split('=')[0],
      value: param.split('=')[1]
    }))
    if (typeof params.filter === 'string') {
      setFilter(params.filter)
    }
  }, [])

  useEffect(() => {
    const { updateIsRequesting, updateIsError } = keys
    const madeSuccessfulUpdate =
      !updateIsRequesting &&
      !updateIsError &&
      _.get(prevKeys, 'updateIsRequesting') !== updateIsRequesting
    const updateRequestStarted =
      updateIsRequesting &&
      _.get(prevKeys, 'updateIsRequesting') !== updateIsRequesting

    if (madeSuccessfulUpdate) {
      setTimeout(() => {
        closeModal()
      }, 1000)
    }

    if (updateRequestStarted && unit !== null && room !== null) {
      setModalContent(() => getNewModalTemplate(unit, room, unitSpace))
    }

    if (resetPage === true) setResetPage(false)

    if (
      (!_.isEqual(location, prevLocation) ||
        (!editInitialized &&
          (!_.isEqual(user, prevUser) || !_.isEqual(auth, prevAuth)) &&
          (_.get(prevAuth, 'isAuthenticated') &&
            _.get(prevAuth, 'hasMgmtToken')))) &&
      user.email
    ) {
      setAllowEdit(sectionManageable('/keys', user))
      setEditInitialized(true)
    }
  }, [keys, unit, resetPage, user, auth, location])

  useEffect(() => {
    document.addEventListener('click', onWindowClick)
    return () => document.removeEventListener('click', onWindowClick)
  }, [])

  const onWindowClick = e => {
    if (e.target && !isDescendant(e.target, 'more-info')) {
      const moreInfoButton = document.getElementById('more-info')
      if (moreInfoButton && moreInfoButton.classList.contains('active')) {
        moreInfoButton.classList.remove('active')
      }
    }
  }

  const closeModalHandler = useCallback(() => {
    setModalContent(null)
    closeModal()
  }, [closeModal])

  const iconClicked = (unit, key, mData) => {
    let room, unitSpace

    if (mData) {
      room = mData.unitspace
      unitSpace = mData
    } else {
      room = key.split('.')[1]
      unitSpace = unit.unitSpaces[room]
    }

    if (allowEdit) {
      setModalContent(() => getNewModalTemplate(unit, room, unitSpace))
      openModal({ width: '400px' })
      setUnit(unit)
      setRoom(room)
      setUnitspace(unitSpace)
    }
  }

  const getNewModalTemplate = (unit, room, unitSpace) => {
    return (
      <KeysModal
        context={context}
        unit={unit}
        unitSpace={unitSpace}
        room={room}
        modal={modal}
        updateKey={updateKey}
        user={user}
        closeModal={closeModalHandler}
      />
    )
  }

  const handleOpenSyncKeysModal = () => {
    setModalContent(() => (
      <KeysSyncModal
        keysSource={keysSyncState?.keysSource}
        onSave={() => {
          setPendingRefresh(true)
          onSyncKeys()
          closeModal()
        }}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({ width: '480px', maxWidth: '94%' })
  }

  /*const getModalTemplate = (unit, room) => {
    const { updateIsRequesting } = keys
    const r = unit.unitSpaces[room]
    const hasOptions = r.status !== 'R' && r.status !== 'V'
    const keyReturned = 'Yes'
    const holdover = 'holdover'
    const undo = 'No'
    const isReturned = r.status === 'Yes'
    const isHoldover = r.status === 'holdover'
    return (
      <div className="columns">
        <div className="column is-full">
          <div className="columns m-b-none">
            <div className="column is-full p-b-none">
              <div
                className="is-pulled-right is-pointer"
                onClick={() => {
                  closeModal()
                }}>
                X
              </div>
              <div className="is-size-5">
                {unit.unit} - Room {r.unitspace}
              </div>
              <div className={`is-size-6 ${hasOptions ? 'm-b-lg' : ''}`}>
                Current Resident: {r.resident}
              </div>
            </div>
          </div>
          <div
            className={`columns ${
              hasOptions && updateIsRequesting ? '' : 'is-hidden'
            }`}>
            <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm m-l-md" />
            Updating...
          </div>
          <div
            className={`columns ${
              hasOptions && !updateIsRequesting ? '' : 'is-hidden'
            }`}>
            <div className="column is-full">
              <div className="is-size-6 m-b-sm">Select one:</div>
              <div className="columns">
                <div className="column is-full p-l-lg p-r-lg">
                  <div
                    className={`is-size-6 m-b-sm is-unselectable p-t-sm p-b-sm p-l-xs p-r-xs ${
                      isReturned ? 'has-background-success' : ''
                    }`}>
                    <img
                      alt="Check icon"
                      src={keysIcons.checkIcon}
                      style={iconStyle}
                      className="m-r-sm is-pointer"
                      onClick={() =>
                        modalOptionClicked(unit.unit, room, keyReturned)
                      }
                    />
                    <p
                      className="is-inline is-pointer"
                      onClick={() =>
                        modalOptionClicked(unit.unit, room, keyReturned)
                      }>
                      Key returned
                    </p>
                    <p
                      className={`is-size-7 is-inline is-pointer has-text-light has-text-underlined m-l-md ${
                        isReturned ? '' : 'is-hidden'
                      }`}
                      onClick={() => modalOptionClicked(unit.unit, room, undo)}>
                      Undo
                    </p>
                  </div>
                  <div
                    className={`is-size-6 is-unselectable p-t-sm p-b-sm p-l-xs p-r-xs ${
                      isHoldover ? 'has-background-danger' : ''
                    }`}>
                    <img
                      alt="X Icon"
                      src={keysIcons.xIcon}
                      style={iconStyle}
                      className="m-r-sm is-pointer"
                      onClick={() =>
                        modalOptionClicked(unit.unit, room, holdover)
                      }
                    />
                    <p
                      className="is-inline is-pointer"
                      onClick={() =>
                        modalOptionClicked(unit.unit, room, holdover)
                      }>
                      Holdover
                    </p>
                    <p
                      className={`is-size-7 is-inline is-pointer has-text-light has-text-underlined m-l-md ${
                        isHoldover ? '' : 'is-hidden'
                      }`}
                      onClick={() => modalOptionClicked(unit.unit, room, undo)}>
                      Undo
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }*/

  /*const modalOptionClicked = (unit, room, status) => {
    const { pdbid } = user.user_metadata
    updateKey(pdbid, context, unit, room, status)
  }*/

  const cardClicked = id => {
    const newValue = filter === id ? null : id
    setFilter(newValue)
    setResetPage(true)
  }

  const getFilteredList = () => {
    const { list } = keys
    let filterFunc = () => true
    switch (filter) {
      case 'expected':
        filterFunc = row => {
          let match = false
          for (let i in row.unitSpaces) {
            const us = row.unitSpaces[i]
            if (us.status !== 'R' && us.status !== 'V') match = true
          }
          return match
        }
        break
      case 'returned':
        filterFunc = row => {
          let match = false
          for (let i in row.unitSpaces) {
            const us = row.unitSpaces[i]
            if (us.status === 'Yes') match = true
          }
          return match
        }
        break
      case 'potential_holdovers':
        filterFunc = row => {
          let match = false
          for (let i in row.unitSpaces) {
            const us = row.unitSpaces[i]
            if (
              us.status !== 'Yes' &&
              us.status !== 'holdover' &&
              us.status !== 'R' &&
              us.status !== 'V' &&
              us.tentant_status !== 'RTO' &&
              us.tentant_status !== 'Leapfrog'
            )
              match = true
          }
          return match
        }
        break
      case 'holdovers':
        filterFunc = row => {
          let match = false
          for (let i in row.unitSpaces) {
            const us = row.unitSpaces[i]
            if (us.status === 'holdover') match = true
          }
          return match
        }
        break
      case 'leapfrogs':
        filterFunc = row => {
          let match = false
          for (let i in row.unitSpaces) {
            const us = row.unitSpaces[i]
            if (us.tentant_status === 'Leapfrog') match = true
          }
          return match
        }
        break
      case 'rti':
        filterFunc = row => {
          let match = false
          for (let i in row.unitSpaces) {
            const us = row.unitSpaces[i]
            if (us.tentant_status === 'RTI') match = true
          }
          return match
        }
        break
      case 'rto':
        filterFunc = row => {
          let match = false
          for (let i in row.unitSpaces) {
            const us = row.unitSpaces[i]
            if (us.tentant_status === 'RTO') match = true
          }
          return match
        }
        break
      default:
        break
    }

    return list.filter(filterFunc)
  }

  const openNotes = row => {
    setModalContent(() => (
      <Notes
        unit={row.unit}
        unitId={row.unit_id}
        service="Keys"
        onClose={closeModal}
      />
    ))
    openModal({ width: MODAL_WIDTH })
  }

  const isLoading =
    !keys.listHasRequested || keys.listIsRequesting || keys.updateIsRequesting

  const { serviceColumns } = services || {}

  const hd = headerData(serviceColumns)

  const getKeyStatus = status => {
    if (status === null) return ''
    switch (status) {
      case 'Yes':
        return 'Key Returned'
      case 'No':
        return 'Key Expected'
      case 'R':
        return 'Renewal'
      case 'V':
        return 'Vacant'
      case 'holdover':
        return 'Holdover'
      default:
        return ''
    }
  }
  const getTenantStatus = status => {
    if (status === null) return ''
    switch (status) {
      case 'RTI':
        return 'Renewal Transfer In'
      case 'RTO':
        return 'Renewal Transfer Out'
      case 'Leapfrog':
        return 'Leapfrog'
      default:
        return ''
    }
  }

  const constructDataForCSV = customData => {
    const updatedData = []
    for (const parentDataKey in customData) {
      const { unit, unitSpaces } = customData[parentDataKey] || {}
      for (const ChildDataKey in unitSpaces) {
        const tenant_status = unitSpaces[ChildDataKey]?.tentant_status
        const key_status = unitSpaces[ChildDataKey]?.status
        updatedData.push({
          Unit: unit,
          Space: ChildDataKey?.toString()?.toUpperCase(),
          Resident: unitSpaces[ChildDataKey]?.resident,
          Status: getKeyStatus(key_status),
          'Returned Date': unitSpaces[ChildDataKey]?.returned_date,
          Holdover: unitSpaces[ChildDataKey]?.holdover,
          'Tenant Status': getTenantStatus(tenant_status)
        })
      }
    }
    return updatedData
  }

  const exportToCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = (properties || []).find(p => p.id === contextProperty)
    const project = (projects || []).find(p => p.id === contextProject)
    const names = {
      property: property?.name?.replace(/ /g, '_'),
      project: project?.name?.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const getFilteredData =
      (tableRef?.current?.getCheckRows() || []).length === 0
        ? tableRef?.current?.getRows() || []
        : tableRef?.current?.getCheckRows() || []
    const updatedData = constructDataForCSV(getFilteredData)
    const data = updatedData
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(updatedData.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names?.project}-${names?.property}-all-keys-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className="container animated fadeIn">
      <div className="section">
        <div className="columns is-marginless">
          <div className="column is-one-half is-paddingless">
            <Breadcrumbs location={location} />
          </div>
        </div>
        <KeysStats
          keys={keys}
          responsive={responsive}
          filter={filter}
          cardClicked={id => cardClicked(id)}
        />
        <DataTable
          ref={tableRef}
          header={hd}
          title="Key Tracker"
          allowFilter={false}
          textFilter={searchBox}
          onSetTextFilter={setSearchBox}
          data={getFilteredList()}
          isLoading={isLoading}
          iconClicked={iconClicked}
          resetPage={resetPage}
          buttonsSection={
            <>
              <PermissionGate name="update-sync-keys">
                {keysSyncState.status !== 'inprogress' &&
                  !keysSyncState.keysSyncRequested &&
                  pendingRefresh && (
                    <span className="refresh-caveat small is-hidden-tablet">
                      psst…Your page is out of sync. Click “Refresh Page” to get
                      newest data.
                    </span>
                  )}
                <div
                  className="columns is-vcentered is-mobile m-b-none"
                  style={{ position: 'relative' }}>
                  {keysSyncState.status !== 'inprogress' &&
                    !keysSyncState.keysSyncRequested &&
                    pendingRefresh && (
                      <span className="refresh-caveat is-hidden-mobile">
                        psst…Your page is out of sync. Click “Refresh Page” to
                        get newest data.
                      </span>
                    )}
                  <div className="column is-narrow p-r-xs is-half-mobile">
                    <button
                      className="button is-primary main-button"
                      disabled={
                        keysSyncState.keysSyncRequested ||
                        keysSyncState.status === 'inprogress'
                      }
                      type="button"
                      onClick={handleOpenSyncKeysModal}>
                      {keysSyncState.keysSyncRequested ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        <span>
                          {keysSyncState.status === 'inprogress'
                            ? 'Fetching Data...'
                            : 'Sync'}
                        </span>
                      )}
                    </button>
                  </div>
                  <div className="column is-narrow is-half-mobile">
                    <button
                      className="button is-secondary m-r-none main-button"
                      onClick={() => {
                        setPendingRefresh(false)
                        onRefresh()
                        const {
                          user_metadata: { pdbid }
                        } = user

                        dispatch(getIncompleteUnitSpaces(pdbid,context, currentProperty.role.id))
                      }}
                      disabled={
                        keys.listIsRequesting || keys.statsIsRequesting
                      }>
                      {keys.listIsRequesting || keys.statsIsRequesting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        <span>Refresh Page</span>
                      )}
                    </button>
                  </div>
                </div>
              </PermissionGate>
              <div
                className="columns is-vcentered is-mobile m-b-none"
                style={{ position: 'relative' }}>
                <div className="column is-narrow p-r-xs is-half-mobile">
                  {keysSyncState.keysSource ? (
                    <div
                      className="columns m-t-none m-b-none"
                      style={{ alignItems: 'center' }}>
                      <div className="column is-narrow-tablet p-t-none p-b-none">
                        Lease Source: {keysSyncState.keysSource}
                        <div className="is-hidden-tablet">
                          Last Sync:{' '}
                          {getFormattedDateTime(keysSyncState.lastSync)}
                          {keysSyncState.status !== 'inprogress' && (
                            <span
                              className={`sync-tooltip ${
                                keysSyncState.status === 'error'
                                  ? 'break-text'
                                  : ''
                              }`}
                              data-custom-tooltip={
                                keysSyncState.status === 'complete'
                                  ? `Your last sync to ${keysSyncState.keysSource} was successful!`
                                  : `Oops!${'\n\n'}We weren’t able to sync the data from ${
                                      keysSyncState.keysSource
                                    } on last sync.${'\n\n'}Review the following error message:${
                                      keysSyncState.errorMessage
                                        ? '\n\n' + keysSyncState.errorMessage
                                        : ''
                                    }`
                              }>
                              {keysSyncState.status === 'complete' && (
                                <img src={checkIcon} alt="Verified" />
                              )}
                              {keysSyncState.status === 'error' && (
                                <img
                                  src={alertIcon}
                                  alt="Error"
                                  style={{
                                    width: 17,
                                    marginTop: 5,
                                    marginLeft: 5
                                  }}
                                />
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="column divider is-narrow is-hidden-mobile" />
                      <div
                        className="column is-hidden-mobile p-t-none p-b-none"
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          minWidth: 262
                        }}>
                        Last Sync:{' '}
                        {getFormattedDateTime(keysSyncState.lastSync)}
                        {keysSyncState.status !== 'inprogress' && (
                          <span
                            className={`sync-tooltip ${
                              keysSyncState.status === 'error'
                                ? 'break-text'
                                : ''
                            }`}
                            data-custom-tooltip={
                              keysSyncState.status === 'complete'
                                ? `Your last sync to ${keysSyncState.keysSource} was successful!`
                                : `Oops!${'\n\n'}We weren’t able to sync the data from ${
                                    keysSyncState.keysSource
                                  } on last sync.${'\n\n'}Review the following error message:${
                                    keysSyncState.errorMessage
                                      ? '\n\n' + keysSyncState.errorMessage
                                      : ''
                                  }`
                            }>
                            {keysSyncState.status === 'complete' && (
                              <img src={checkIcon} alt="Verified" />
                            )}
                            {keysSyncState.status === 'error' && (
                              <img
                                src={alertIcon}
                                alt="Error"
                                style={{
                                  width: 17,
                                  marginTop: 5,
                                  marginLeft: 5
                                }}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          }
          additionalOptions={
            <button
              className="button tool-button more-info"
              ref={morInfoBtn}
              id="more-info"
              onClick={e => {
                morInfoBtn.current.classList.toggle('active')
              }}>
              <img
                src={iconQuestionCircle}
                alt="Icon Legend"
                className={'icon is-primary m-r-sm'}
              />
              More Info
            </button>
          }
          onExport={() => exportToCSV()}
          filterKey={['unit', 'unit-resident']}
          filterValue={searchBox}
          tooltip={tooltip}
          tooltipSizeAuto={true}
          openTooltip={openTooltip}
          closeTooltip={closeTooltip}
          setTooltipContent={setTooltipContent}
          tooltipTemplate={tt => {
            if (typeof tt === 'undefined' || tt === null) return null
            return (
              <div className="p-md">
                <div className="m-b-sm" style={{ fontWeight: 'bold' }}>
                  {tt.unit} - Room {tt.room.toUpperCase()}
                </div>
                <div className="m-b-sm">Current Resident: {tt.resident}</div>
                <div>{`${
                  tt.returned_date !== '-Not Set-'
                    ? `Key Returned: ${tt.returned_date}`
                    : ''
                }`}</div>
              </div>
            )
          }}
          methods={{ openNotes: () => openNotes() }}
        />
      </div>
    </div>
  )
}

KeysMain.propTypes = {
  context: PropTypes.object,
  keys: PropTypes.object,
  user: PropTypes.object,
  auth: PropTypes.object,
  location: PropTypes.object,
  responsive: PropTypes.object,
  hasRequested: PropTypes.bool,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  updateKey: PropTypes.func
}

export default KeysMain
