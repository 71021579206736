import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import DatePickerInput from '../../components/date-picker'
import { formatDateString } from '../../utils/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'
import _, { isEmpty } from 'lodash'
import './index.scss'

const AddInspectionModal = props => {
  const {
    onClose,
    getInspectionFormList,
    inspect,
    user,
    context,
    getInspectionUnitList,
    addNewInspection,
    getInspectionList,
    getInspectionDownloadMediaURL,
    editInspectionData,
    isEditInspection = false,
    getInspectionAssignToList,
    editNewInspection,
    setUpdateInspectionList
  } = props || {}
  // Redux
  const { inspectionFormList, inspectionUnitList, inspectionAssignToList, inspectionTeamsAssignToList } =
    inspect || {}
  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    getInspectionFormList(pdbid, context)
    getInspectionUnitList(pdbid, context)
    getInspectionAssignToList(pdbid, context)
  }, [])

  // States
  const [selectedUnits, setSelectedUnits] = useState([])
  const [allUnits, setAllUnits] = useState([])
  const [allSelectedUnits, setAllSelectedUnits] = useState([])
  const [checkedAllUnits, setCheckedAllUnits] = useState(false)
  const [assignToTeamCheck, setassignToTeamCheck] = useState(false)
  const [form, setForm] = useState(null)
  const [assignTo, setAssignTo] = useState('')
  const [assignTeamTo, setAssignTeamTo] = useState('')
  const [dueDate, setDueDate] = useState(new Date())
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if ((inspectionUnitList || []).length !== 0) {
      setAllUnits([])
      const getBuilding = (inspectionUnitList || []).flatMap(
        buildingList => buildingList.buildings
      )
      const getUnits = (getBuilding || []).flatMap(unitList => unitList.units)
      setAllUnits(getUnits || [])
    }
  }, [inspectionUnitList])

  // Re-fill inspection data into fields for update
  useEffect(() => {


    if (Object.keys(editInspectionData || {}).length !== 0) {
      if ((allUnits || []).length !== 0) {
        const { unit, inspection_date, form_id, assigned_to, team_id } =
          editInspectionData || {}
        const getSelectedUnit = (allUnits || []).filter(
          item => item.unit_name === unit
        )
        const getSelectedForm = (inspectionFormList || []).filter(
          item => item.form_id === form_id
        )
        if(assigned_to != null)
       { const getSelectedAssignTo = (inspectionAssignToList || []).filter(
          item => item.auth_id === assigned_to
        )
        if ((getSelectedAssignTo || []).length !== 0) {
          setassignToTeamCheck(false)
          setAssignTo(getSelectedAssignTo[0].auth_id || '')
          
        }}

        if(team_id != null)
          { const getSelectedTeamAssignTo = (inspectionTeamsAssignToList || []).filter(
             item => item.uri === team_id
           )

           if ((getSelectedTeamAssignTo || []).length !== 0) {
            setassignToTeamCheck(true)
            setAssignTeamTo(getSelectedTeamAssignTo[0].uri || '')
            
           }}

        if ((getSelectedUnit || []).length !== 0) {
          setSelectedUnits(getSelectedUnit || [])
        }
        if ((getSelectedForm || []).length !== 0) {
          setForm(getSelectedForm[0])
        }
       
        setDueDate(inspection_date ? new Date(inspection_date) : new Date())
      }
    }
  }, [
    editInspectionData,
    allUnits,
    inspectionUnitList,
    inspectionFormList,
    inspectionAssignToList,
    inspectionTeamsAssignToList
  ])

  // Create new inspection
  const onCreateNewInspection = event => {
    event.preventDefault()
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}

    if (
      isEmpty(form) ||
      ((selectedUnits || []).length === 0 &&
        (allSelectedUnits || []).length === 0)
    ) {
      setErrorMessage('You need to fill all the required fields')
      return
    }
    setSubmitting(true)
    let body = {
      form_id: form?.form_id,
      assigned_to: assignToTeamCheck? null: assignTo,
      team_id: assignToTeamCheck? assignTeamTo: null,
      due_date: formatDateString(dueDate, 'DD-MMM-YY')
    }
    if (checkedAllUnits) {
      body.units = (allSelectedUnits || []).map(data => {
        return { unit_id: data.unit_id }
      })
    } else {
      body.units = (selectedUnits || []).map(data => {
        return { unit_id: data.unit_id }
      })
    }
    addNewInspection({
      pdbid,
      context,
      body,
      onSuccess: () => {
        setUpdateInspectionList(true)
        onClose()
        setErrorMessage('')
      },
      onError: error => {
        setSubmitting(false)
        setErrorMessage('Something went wrong. Please try again later !!')
      }
    })
  }

  // Edit inspection
  const onEditNewInspection = event => {
    event.preventDefault()
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}

    setSubmitting(true)
    const {
      inspection_id,
      status,
      approved_date,
      submitted_date,
      approved_by
    } = editInspectionData || {}
    let body = {
      assigned_to: assignToTeamCheck? null: assignTo,
      team_id: assignToTeamCheck? assignTeamTo: null,
      inspection_date: formatDateString(dueDate, 'DD-MMM-YY'),
      status,
      approved_date,
      submitted_date,
      approved_by
    }
    editNewInspection({
      pdbid,
      context,
      body,
      inspectionId: inspection_id,
      onSuccess: () => {
        setUpdateInspectionList(true)
        onClose()
        setErrorMessage('')
      },
      onError: error => {
        setSubmitting(false)
        setErrorMessage('Something went wrong. Please try again later !!')
      }
    })
  }

  const onChangeSelectedUnit = units => {
    setSelectedUnits(units)
    setCheckedAllUnits(false)
    setAllSelectedUnits([])
  }

  const onHandleCheckAllUnits = () => {
    setCheckedAllUnits(!checkedAllUnits)
    setAssignTo('')
    setSelectedUnits([])
    setAllSelectedUnits([])
  }

  if (checkedAllUnits) {
    if ((allSelectedUnits || []).length === 0) {
      setAllSelectedUnits(allUnits || [])
    }
  }

  // Save button Text based on new/edit state
  const getSaveButtonContent = () => {
    if (isEditInspection) {
      return isSubmitting ? 'Saving' : 'Save'
    }
    return isSubmitting ? 'Creating' : 'Create'
  }

  // On Save
  const onSave = e => {
    if (isEditInspection) {
      return onEditNewInspection(e)
    }
    return onCreateNewInspection(e)
  }

  const checkCanSubmit =
    isEmpty(form) ||
    ((selectedUnits || []).length === 0 &&
      (allSelectedUnits || []).length === 0)

  return (
    <div className="inspection-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            {isEditInspection ? 'Edit Inspection' : 'New Inspection'}
          </p>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full">
        <form>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>Form:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <MultiSelectDropdown
                displayKey="name"
                isDisabled={isEditInspection}
                value={form}
                defaultValues={inspectionFormList || []}
                placeholder="Select"
                onChange={value => setForm(value)}
                isMulti={false}
                isClearable
                customStyles={{
                  valueContainer: provided => ({
                    ...provided,
                    minHeight: '45px',
                    maxHeight: '100px',
                    overflow: 'auto',
                    position: 'relative'
                  })
                }}
              />
            </div>
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>Unit:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <div className="unit-select">
                <MultiSelectDropdown
                  displayKey="unit_name"
                  isDisabled={isEditInspection}
                  value={selectedUnits}
                  defaultValues={allUnits || []}
                  placeholder="Select"
                  onChange={v => onChangeSelectedUnit(v)}
                  customStyles={{
                    valueContainer: provided => ({
                      ...provided,
                      minHeight: '45px',
                      maxHeight: '100px',
                      overflow: 'auto',
                      position: 'relative'
                    })
                  }}
                />
              </div>
              <label className={`checkbox ${checkedAllUnits && 'is-active'}`}>
                <span
                  className={`checkbox-input ${checkedAllUnits &&
                    'is-active'}`}>
                  <span className="checkbox-inner" />
                  <input
                    type="checkbox"
                    className="checkbox-original"
                    checked={checkedAllUnits}
                    name="all_units"
                    value={checkedAllUnits}
                    disabled={isEditInspection}
                    onChange={() => onHandleCheckAllUnits()}
                  />
                </span>
                <span
                  className={`checkbox-label ${checkedAllUnits &&
                    'is-active'}`}>
                  Create for all units
                </span>
              </label>
            </div>
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>Assign To:</label>
            </div>
            <div className="input-field column is-two-thirds">
            <label className={`checkbox ${assignToTeamCheck && 'is-active'}`}>
                <span
                  className={`checkbox-input ${assignToTeamCheck &&
                    'is-active'}`}>
                  <span className="checkbox-inner" />
                  <input
                    type="checkbox"
                    className="checkbox-original"
                    checked={assignToTeamCheck}
                    name="all_units"
                    value={assignToTeamCheck}
                    // disabled={isEditInspection}
                    onChange={() => setassignToTeamCheck(!assignToTeamCheck)}
                  />
                </span>
                <span
                  className={`checkbox-label ${assignToTeamCheck &&
                    'is-active'}`}>
                  Assign to a team
                </span>
              </label>
                {assignToTeamCheck ? <select
                className="input is-inline"
                onChange={e => setAssignTeamTo(e.target.value)}
                value={assignTeamTo}
                disabled={checkedAllUnits}>
                <option value="">Select Team</option>
                {(inspectionTeamsAssignToList || []).map((formVal, i) => {
                  const { member, uri } = formVal || {}
                  return (
                    <option value={uri || ''} key={i}>
                      {member || ''}
                    </option>
                  )
                })}
              </select> :
               <select
               className="input is-inline"
               onChange={e => setAssignTo(e.target.value)}
               value={assignTo}
               disabled={checkedAllUnits}>
               <option value="">Select user</option>
               {(inspectionAssignToList || []).map((formVal, i) => {
                 const { name, auth_id } = formVal || {}
                 return (
                   <option value={auth_id || ''} key={i}>
                     {name || ''}
                   </option>
                 )
               })}
             </select>
              }
              
              
            </div>
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>Due Date:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <DatePickerInput
                value={dueDate}
                minDate={isEditInspection ? dueDate : new Date()}
                onChange={v => setDueDate(v)}
              />
            </div>
          </div>
          {errorMessage !== '' && (
            <div className="notification is-danger is-light is-flex">
              <p>{errorMessage}</p>
              <button onClick={() => setErrorMessage('')}>
                <img src={closeFilled} alt="" />
              </button>
            </div>
          )}
          <div className="columns is-vcentered m-b-sm m-t-sm">
            <div className="center-container">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => {
                  onClose()
                }}>
                Cancel
              </button>
              <button
                disabled={!isEditInspection && checkCanSubmit}
                className="button main-button is-primary"
                onClick={event => onSave(event)}>
                {getSaveButtonContent()}
                {isSubmitting && (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AddInspectionModal.propTypes = {
  onClose: PropTypes.func,
  getInspectionUnitList: PropTypes.func,
  getInspectionFormList: PropTypes.func,
  addNewInspection: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object,
  inspect: PropTypes.object,
  editInspectionData: PropTypes.object,
  isEditInspection: PropTypes.bool,
  getInspectionAssignToList: PropTypes.func
}

const mapStateToProps = ({ inspect }) => ({
  inspect
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInspectionModal)
