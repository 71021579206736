import React, { useState, useCallback } from 'react'
import InspectionViewContent from './InspectionViewContent'
import InspectionEditContent from './InspectionEditContent'
import InspectionUnsavedModal from '../inspection-unsaved-modal'
import InspectionPDFModal from '../inspection-pdf-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { formatDateString } from '../../utils/index'
import { useModals } from 'hooks'
import ConfirmationModal from '../../components/confirmation-modal'
import { closeFilled } from 'ui/icons'
import { exportSingleInspectionCSV } from '../inspection-list/components/reusableInspectionMethods'
import PropTypes from 'prop-types'
import { capitalize } from '../../utils/helperFunctions'
import { usePermissionGate } from '../../helpers/hooks'

const InspectionContent = props => {
  const {
    inspectionData,
    location,
    history,
    updateInspectionItem,
    user,
    context,
    unFilterInspectionData,
    inspectionUploadMediaURL,
    setIsInspectionStatusUpdated,
    getInspectionPDFURL,
    inspect
  } = props || {}
  const {
    inspection_lines,
    status: inspectionStatus,
    type,
    unit,
    inspection_id: id,
    inspection_date: date,
    assigned_to_name: inspector,
    team_name,
    submitted_date,
    approved_date,
    approved_by,
    approved_by_name
  } = inspectionData || {}
  let inspectiorOrTeam = ''
  if (team_name) {
    inspectiorOrTeam = team_name
  } else if (inspector) {
    inspectiorOrTeam = inspector
  } else {
    inspectiorOrTeam = ''
  }
  const [showModal, dismissModal] = useModals()
  // States
  const [isEdit, setIsEdit] = useState(false)
  const [showPDFModal, setShowPDFModal] = useState(false)
  const [isPDFLoading, setIsPDFLoading] = useState(false)
  const [isReopenLoading, setIsReopenLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [currentInspection, setCurrentInspection] = useState(
    inspection_lines || []
  )
  const [pendingSaving, setPendingSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { pathname } = location || {}
  const { hasPermission } = usePermissionGate('add-edit-inspection')

  // Get parent path name
  const getPath = () => {
    const start_pos = pathname.indexOf('/') + 1
    const end_pos = pathname.indexOf('/', start_pos)
    return pathname.substring(start_pos, end_pos)
  }

  const onChangeIsEdit = () => {
    setIsEdit(v => !v)
  }

  // Set Date Label based on condition
  const showInspectionDateByConditions = () => {
    let label = 'DUE'
    let isClass = ''
    let currentDate = date
    if (inspectionStatus === 'Submitted') {
      label = 'SUBMITTED'
      currentDate = submitted_date
    }
    if (inspectionStatus === 'Approved') {
      label = 'APPROVED'
      isClass = 'is-approved'
      currentDate = approved_date
    }
    if (inspectionStatus === 'Damages Approved') {
      label = 'DAMAGED APPROVED'
      isClass = 'is-approved'
      currentDate = approved_date
    }
    return (
      <span className={`inspection-date ${isClass}`}>{`${label}: ${
        date ? formatDateString(currentDate, 'MM/DD/YYYY') : `${label} DATE`
      }`}</span>
    )
  }

  // Determine to disable 'Save' and 'Submit' button
  const checkButtonState = (data = []) => {
    if ((data || []).length !== 0) {
      const checkSubmitState = (data || []).flatMap(inspection => {
        let buttonState = []
        const { items, status } = inspection || {}
        if (status !== 'Not Required') {
          ;(items || []).map(item => {
            const { state, observations, line_media } = item || {}
            if (state === null) {
              return buttonState.push(true)
            }
            if (state === 'Good') {
              return buttonState.push(false)
            }
            if (state === 'Needs Work') {
              const checkSelectedObservation = (observations || []).filter(
                observation => observation.selected
              )
              if ((checkSelectedObservation || []).length !== 0) {
                const getAllSolutions =
                  checkSelectedObservation[0]?.solutions || []
                const isPhotoRequired =
                  checkSelectedObservation[0]?.media_required === null
                    ? true
                    : false
                if (isPhotoRequired) {
                  const checkMediaMinimum =
                    checkSelectedObservation[0]?.media_minimum || 1
                  if (Number(checkMediaMinimum) > (line_media || []).length) {
                    return buttonState.push(true)
                  }
                }

                if ((getAllSolutions || []).length !== 0) {
                  const checkSelectedSolutions = (getAllSolutions || []).filter(
                    solution => solution.selected
                  )
                  if ((checkSelectedSolutions || []).length !== 0) {
                    return buttonState.push(false)
                  }
                }
              }
              return buttonState.push(true)
            }
          })
          return buttonState
        }
      })
      const isDisabled = (checkSubmitState || []).includes(true)
      return isDisabled
    }
  }

  // To check and re-calculate status based on internal state value
  const isInternalSubmitDisable = useCallback(
    checkButtonState(currentInspection || []),
    [currentInspection]
  )
  // To check and re-calculate status based on api state value
  const isSubmitButtonDisable = useCallback(
    checkButtonState(inspection_lines || []),
    [inspection_lines]
  )

  // Save inspection
  const onSaveInspection = async (updatedStatus = '') => {
    setErrorMessage('')
    const { user_metadata, user_id } = user || {}
    const { pdbid } = user_metadata || {}
    let updateBody = unFilterInspectionData || []
    // Setup current data to update
    if ((unFilterInspectionData || []).length !== 0) {
      updateBody[0] = {
        ...updateBody[0],
        inspection_lines: currentInspection || []
      }
      // If status is given
      if (updatedStatus) {
        let updatedObject = {
          approved_date,
          approved_by,
          submitted_date
        }
        const current_date = formatDateString(new Date(), 'DD-MMM-YY')
        if (updatedStatus === 'Approved') {
          updatedObject.approved_date = current_date
          updatedObject.approved_by = user_id || ''
        }
        if (updatedStatus === 'Submitted') {
          updatedObject.submitted_date = current_date
        }
        if (updatedStatus === 'Reopened') {
          updatedObject.submitted_date = null
          updatedObject.approved_date = null
          updatedObject.approved_by = null
        }
        updateBody[0] = {
          ...updateBody[0],
          status: updatedStatus,
          ...updatedObject
        }
      }
      // If status is empty then determine the status
      if (updatedStatus === '') {
        let status = 'Not Started'
        if (
          inspectionStatus === 'Not Started' ||
          isInternalSubmitDisable ||
          (inspectionStatus === 'Started' && isInternalSubmitDisable)
        ) {
          status = 'Started'
        } else if (
          (inspectionStatus === 'Started' && !isInternalSubmitDisable) ||
          inspectionStatus === 'Completed' ||
          inspectionStatus === 'Reopened'
        ) {
          status = 'Completed'
        } else if (inspectionStatus === 'Submitted') {
          status = 'Submitted'
        }
        updateBody[0] = {
          ...updateBody[0],
          status: status
        }
      }
    }

    // Loading states on button
    if (updatedStatus === 'Submitted') {
      setIsSubmitting(true)
    } else if (updatedStatus === 'Reopened') {
      setIsReopenLoading(true)
    } else {
      setIsSaving(true)
    }
    // Saving data
    await updateInspectionItem({
      pdbid,
      context,
      body: updateBody,
      inspection_id: id,
      onSuccess: () => {
        setIsSubmitting(false)
        setIsSaving(false)
        setIsReopenLoading(false)
        setIsEdit(false)
        setPendingSaving(false)
        setErrorMessage('')
        setIsInspectionStatusUpdated(true)
      },
      onError: error => {
        setIsSubmitting(false)
        setIsSaving(false)
        setErrorMessage('Something went wrong, please try again !!')
      }
    })
  }

  // Confirmation Modal for action buttons
  const onHandleApproveModal = ({
    msg,
    submitAction,
    dismissLabel,
    modalTitle,
    submitLabel,
    subMsg,
    additionalSubmitButtonClasses
  }) => {
    showModal(
      { width: '480px' },
      <ConfirmationModal
        msg={msg}
        submit={submitAction}
        dismissLabel={dismissLabel}
        modalTitle={modalTitle}
        submitLabel={submitLabel}
        subMsg={subMsg}
        additionalSubmitButtonClasses={additionalSubmitButtonClasses}
        dismiss={dismissModal}
      />
    )
  }

  const togglePDFModal = () => {
    setShowPDFModal(!showPDFModal)
  }

  const getItemsState = (items, status) => {
    if (status === 'Not Required') {
      return 'Not Required'
    } else {
      const checkState = (items || []).map(item => item.state)
      let state = ''
      if ((checkState || []).every(element => element !== null)) {
        state = 'Completed'
      } else if ((checkState || []).some(element => element !== null)) {
        state = 'Started'
      }
      return state
    }
  }

  const rewriteInspectionSpaceStatus = inspectionData => {
    let data = inspectionData
    data.inspection_lines = (data?.inspection_lines).map(inspectLines => {
      const { items, status } = inspectLines || {}
      inspectLines.status = getItemsState(items, status)
      return inspectLines
    })
    return data
  }

  const getInspectionPDF = async () => {
    setIsPDFLoading(true)
    // Meta Data
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const body = {
      ids: [id]
    }
    await getInspectionPDFURL({
      pdbid,
      context,
      body: rewriteInspectionSpaceStatus(inspectionData),
      onSuccess: () => {
        setIsPDFLoading(false)
        togglePDFModal()
      },
      onError: error => {
        setIsPDFLoading(false)
        setErrorMessage('Something went wrong, please try again !!')
      }
    })
  }

  const getInspectionActionItem = () => {
    if (inspectionStatus === 'Approved') {
      return (
        <>
          <button onClick={() => getInspectionPDF()} className="pdf-button">
            Print PDF
            {isPDFLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
          <button
            className="pdf-button"
            onClick={() => {
              if (hasPermission) {
                onSaveInspection('Reopened')
              }
            }}
            disabled={!hasPermission}>
            Reopen
            {isReopenLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
          <button
            className="button is-primary"
            onClick={() =>
              exportSingleInspectionCSV([inspectionData], context, user)
            }>
            Export
          </button>
        </>
      )
    }
    if (inspectionStatus === 'Damages Approved') {
      return (
        <>
          <button onClick={() => getInspectionPDF()} className="pdf-button">
            Print PDF
            {isPDFLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>

          <button
            className="button is-primary"
            onClick={() =>
              exportSingleInspectionCSV([inspectionData], context, user)
            }>
            Export
          </button>
        </>
      )
    }
    if (isEdit) {
      return (
        <>
          <button
            className="button is-primary"
            disabled={
              !hasPermission || inspectionStatus === 'Submitted'
                ? isInternalSubmitDisable
                : false
            }
            onClick={() => {
              if (hasPermission) {
                onSaveInspection()
              }
            }}>
            {isSaving ? 'Saving' : 'Save'}
            {isSaving && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
          {inspectionStatus !== 'Submitted' && inspectionStatus !== 'Approved' && (
            <button
              className="button is-success"
              disabled={
                !hasPermission ||
                isSaving ||
                isSubmitButtonDisable ||
                pendingSaving
              }
              onClick={e => {
                e.preventDefault()
                if (hasPermission) {
                  onHandleApproveModal({
                    msg:
                      'You’re about to submit this inspection, which will mark it as complete and allow you to give final approval. You can still make edits until it is approved.',
                    dismissLabel: 'Cancel',
                    modalTitle: 'Confirm',
                    submitLabel: 'Yes',
                    subMsg: 'Would you like to submit this inspection?',
                    submitAction: () => {
                      onSaveInspection('Submitted')
                      dismissModal()
                    }
                  })
                }
              }}>
              {isSubmitting ? 'Submitting' : 'Submit'}
              {isSubmitting && (
                <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
              )}
            </button>
          )}
          <button
            className="button is-outlined"
            onClick={e => {
              e.preventDefault()
              history.push(`/${getPath()}/dashboard`)
            }}>
            Exit
          </button>
        </>
      )
    }
    return (
      <>
        {inspectionStatus === 'Submitted' && (
          <button
            className="button is-success"
            disabled={!hasPermission}
            onClick={e => {
              e.preventDefault()
              if (hasPermission) {
                onHandleApproveModal({
                  msg:
                    'You’re about to approve this inspection. Once approved, the inspector will no longer be able to access the inspection on their mobile device. However, you will still be able to re-open it for the inspector even after approval.',
                  dismissLabel: 'Cancel',
                  modalTitle: 'Confirm',
                  submitLabel: 'Yes',
                  subMsg: 'Would you like to continue with approval?',
                  submitAction: () => {
                    onSaveInspection('Approved')
                    dismissModal()
                  }
                })
              }
            }}>
            {isSubmitting ? 'Approving' : 'Approve'}
            {isSubmitting && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
        )}
        <button
          className="button is-outlined"
          disabled={!hasPermission}
          onClick={e => {
            e.preventDefault()
            if (hasPermission) {
              onChangeIsEdit()
            }
          }}>
          Open & Edit
        </button>
        <button
          className="button is-primary"
          onClick={() =>
            exportSingleInspectionCSV([inspectionData], context, user)
          }>
          Export
        </button>
      </>
    )
  }

  return (
    <>
      <InspectionUnsavedModal
        activePrompt={pendingSaving}
        setIsEdit={setIsEdit}
        setPendingSaving={setPendingSaving}
        updateInspectionItem={updateInspectionItem}
        user={user}
        context={context}
        inspection_id={id}
        currentInspection={currentInspection}
        unFilterInspectionData={unFilterInspectionData}
        isDisable={
          inspectionStatus === 'Submitted' ? isInternalSubmitDisable : false
        }
      />
      <div className="columns">
        <div className="column">
          {errorMessage !== '' && (
            <div className="notification is-danger is-light is-flex">
              <p>{errorMessage}</p>
              <button onClick={() => setErrorMessage('')}>
                <img src={closeFilled} alt="" />
              </button>
            </div>
          )}
          <div className="inspection-main-card">
            <div className="inspection-card-header is-flex">
              <div className="inspection-heading">
                <h2>{`${capitalize(type)} Inspection`}</h2>
                <p>{`Unit ${unit || ''}`}</p>
              </div>
              <div className="inspector">
                <div>{showInspectionDateByConditions()}</div>
                {team_name?
                 <p>{`Inspection Team: ${inspectiorOrTeam || 'Unassigned'}`}</p>:
                 <p>{`Inspector: ${inspectiorOrTeam || 'Unassigned'}`}</p>
                }
                {inspectionStatus === 'Approved' && (
                  <p>{`Approved By: ${approved_by_name || ''}`}</p>
                )}
              </div>
            </div>
            {isEdit ? (
              <InspectionEditContent
                {...props}
                setPendingSaving={setPendingSaving}
                currentInspection={currentInspection}
                setCurrentInspection={setCurrentInspection}
                isEdit={isEdit}
                inspectionUploadMediaURL={inspectionUploadMediaURL}
              />
            ) : (
              <InspectionViewContent
                {...props}
                inspectionData={inspectionData}
                currentInspection={currentInspection}
                isEdit={isEdit}
                unit={unit}
              />
            )}
          </div>
        </div>
        <div className="column is-one-fifth inspection-action-card">
          <div className="floating-action-button">
            {getInspectionActionItem()}
          </div>
        </div>
        {showPDFModal && (
          <InspectionPDFModal
            onToggle={togglePDFModal}
            getInspectionPDFURL={getInspectionPDFURL}
            context={context}
            user={user}
            inspect={inspect}
            unit={`${type || ''} Inspection - Unit ${unit || ''}`}
            id={id}
          />
        )}
      </div>
    </>
  )
}

InspectionContent.propTypes = {
  inspectionData: PropTypes.object,
  isEdit: PropTypes.bool,
  unFilterInspectionData: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
  context: PropTypes.object,
  updateInspectionItem: PropTypes.func,
  inspectionUploadMediaURL: PropTypes.string,
  setIsInspectionStatusUpdated: PropTypes.func
}

export default InspectionContent
